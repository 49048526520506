<template>
  <div>
    <div style="text-align: center;">
      <loading
        :active="isLoading"
        :is-full-page="fullPage"
        :loader="loader"
        :color="color"
        :lock-scroll="lockScroll"
      />
    </div>
    <div class="template-content">
      <!-- Section -->
      <div class="template-section template-section-padding-1 template-clear-fix template-main">
        <!-- Header + subheader -->
        <div class="template-component-header-subheader">
          <h2>{{ cms.service_section_title }}</h2>
          <div></div>
          <span>{{ cms.service_section_sub_title }}</span>
        </div>

        <!-- Text -->
        <div class="template-align-center">
          <p class="template-padding-reset">{{ cms.service_section_description }}</p>
        </div>

        <!-- Space -->
        <div class="template-component-space template-component-space-2"></div>

        <!-- Features list-->
        <div class="template-component-feature-list template-component-feature-list-position-top">
          <!-- Layout 33x33x33% -->
          <ul class="template-layout-33x33x33 template-clear-fix">
            <!-- Left column -->
            <li
              v-for="(item, index) in services"
              :key="index"
              :class="index == 0 ? 'template-layout-column-left' : index == 1 ? 'template-layout-column-center' : 'template-layout-column-right'"
            >
              <span :class="item.icon"></span>
              <h5>{{ item.title}}</h5>
              <p>{{ item.description }}</p>
              <ul class="template-component-list template-margin-top-2">
                <li v-for="(service, index) in JSON.parse(item.services)" :key="index">
                  <span class="template-icon-meta-check"></span>
                  {{ service }}
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>

      <!-- Section -->
      <div class="template-section template-section-padding-reset template-clear-fix">
        <!-- Flex layout -->
        <div class="template-layout-flex template-clear-fix template-background-color-1">
          <!-- Left column -->
          <div :style="{'background-image': 'url(' + this.$imageUrl + mission.left_image + ')'}"></div>

          <!-- Right column -->
          <div>
            <!-- Tabs -->
            <div class="template-component-tab">
              <!-- Navigation -->
              <ul>
                <li>
                  <a href="#tab-1-about-us">{{ mission.about_title }}</a>
                </li>
                <li>
                  <a href="#tab-1-our-mission">{{ mission.mission_title }}</a>
                </li>
              </ul>

              <!-- Panel 1 -->
              <div id="tab-1-about-us">
                <p
                  class="template-component-italic template-padding-reset"
                >{{ mission.about_desc_one }}</p>
                <p class="template-padding-reset template-margin-top-2">{{ mission.about_desc_two }}</p>
              </div>

              <!-- Panel 2 -->
              <div id="tab-1-our-mission">
                <p
                  class="template-component-italic template-padding-reset"
                >{{ mission.mission_desc_one }}</p>
                <p
                  class="template-padding-reset template-margin-top-2"
                >{{ mission.mission_desc_two }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Section -->
      <div class="template-section template-clear-fix template-main">
        <!-- Header + subheader -->
        <div class="template-component-header-subheader">
          <h2>{{ cms.projects_section_title }}</h2>
          <div></div>
          <span>{{ cms.projects_section_sub_title }}</span>
        </div>

        <!-- Gallery -->
        <div class="template-component-gallery">
          <!-- Filter buttons list -->
          <ul class="template-component-gallery-filter-list">
            <li>
              <a
                href="#"
                @click.prevent="getProjects(0)"
                class="template-filter-all template-state-selected"
              >All</a>
            </li>
            <li v-for="(item, index) in project_categories" :key="index">
              <a
                href="#"
                @click.prevent="getProjects(item.id)"
                class="template-filter-hand-wash"
              >{{ item.title }}</a>
            </li>
          </ul>

          <!-- Images list -->
          <ul class="template-component-gallery-image-list">
            <!-- Image -->
            <li v-for="(item, index) in projects" :key="index" class="template-filter-hand-wash">
              <div class="template-component-image template-component-image-preloader">
                <!-- Orginal image -->
                <a
                  :href="this.$imageUrl + item.image"
                  class="template-fancybox"
                  data-fancybox-group="gallery-1"
                >
                  <!-- Thumbnail -->
                  <img :src="this.$imageUrl + item.image" />

                  <!-- Image hover -->
                  <span class="template-component-image-hover">
                    <span>
                      <span>
                        <span class="template-component-image-hover-header">{{ item.title }}</span>
                        <span class="template-component-image-hover-divider"></span>
                        <span
                          class="template-component-image-hover-subheader"
                        >{{ item.category.title }}</span>
                      </span>
                    </span>
                  </span>
                </a>

                <!-- Fancybox description -->
                <div class="template-component-image-description">{{ item.description }}</div>
              </div>
            </li>
          </ul>
        </div>

        <!-- Button -->
        <div class="template-align-center">
          <a
            :href="cms.browse_projects_button_link"
            @click.prevent="this.$router.push(cms.browse_projects_button_link)"
            class="template-component-button"
          >{{ cms.browse_projects_button_text }}</a>
        </div>
      </div>

      <!-- Google Maps -->
      <div class="template-section template-section-padding-reset template-clear-fix">
        <!-- Google Map -->
        <div class="template-component-google-map">
          <!-- Content -->
          <div class="template-component-google-map-box">
            <div class="template-component-google-map-box-content"></div>
          </div>

          <!-- Button -->
          <a href="javascript:void(0)" class="template-component-google-map-button">
            <span class="template-icon-meta-marker"></span>
            <span class="template-component-google-map-button-label-show">Show Map</span>
            <span class="template-component-google-map-button-label-hide">Hide Map</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";

export default {
  name: "About",
  data() {
    return {
      cms: {},
      services: [],
      mission: {},
      project_categories: [],
      projects: [],
      isLoading: false,
      fullPage: true,
      loader: "bars"
    };
  },
  components: {
    Loading
  },
  async created() {
    await this.getAboutData();
    await this.getProjects(0);

    window.$(".template-component-testimonial-list").templateTestimonial();
    window.$(".template-component-gallery").templateGallery();
    window.$(".template-fancybox").templateFancybox();
    window.$(".template-component-more-link").templateMoreLess();
    window.$(".template-component-image").templateImage();

    window.$(".template-component-tab").templateTab({
      active: 0,
      disabled: false,
      collapsible: false,
      heightStyle: "content",
      show: {
        delay: 0,
        easing: "swing",
        duration: 200
      },
      hide: {
        delay: 0,
        easing: "swing",
        duration: 100
      }
    });

    window
      .$(".template-layout-50x50")
      .responsiveElement({ className: "template-responsive-column-a" });
    window
      .$(".template-layout-33x33x33")
      .responsiveElement({ className: "template-responsive-column-a" });
    window
      .$(
        ".template-layout-25x25x25x25:not(.template-component-counter-box-list>.template-layout-25x25x25x25)"
      )
      .responsiveElement({
        width: 750,
        className: "template-responsive-column-a"
      });
    window
      .$(".template-layout-66x33")
      .responsiveElement({ className: "template-responsive-column-a" });
    window
      .$(".template-layout-33x66")
      .responsiveElement({ className: "template-responsive-column-a" });

    window
      .$(".template-component-counter-box-list>.template-layout-25x25x25x25")
      .responsiveElement({
        width: 940,
        className: "template-responsive-column-a"
      });
  },
  methods: {
    async getAboutData() {
      this.isLoading = true;
      let config = {
        method: "get",
        url: this.$apiBaseUrl + this.$api.about
      };
      await this.$axios(config)
        .then(response => {
          this.cms = response.data.data.cms;
          this.services = response.data.data.services;
          this.mission = response.data.data.mission;
          this.project_categories = response.data.data.project_categories;
          console.log("about", response);
          this.isLoading = false;
        })
        .catch(error => {
          console.log(error);
          this.isLoading = false;
        });
    },
    async getProjects(categoryId) {
      this.isLoading = true;
      let config = {
        method: "get",
        url:
          this.$apiBaseUrl + this.$api.projects + "?category_id=" + categoryId
      };
      await this.$axios(config)
        .then(response => {
          this.projects = response.data.data;
          console.log("projects", response);
          this.isLoading = false;
        })
        .catch(error => {
          console.log(error);
          this.isLoading = false;
        });
    }
  }
};
</script>
