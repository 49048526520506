<template>
  <div>
    <div style="text-align: center;">
      <loading
        :active="isLoading"
        :is-full-page="fullPage"
        :loader="loader"
        :color="color"
        :lock-scroll="lockScroll"
      />
    </div>
    <div class="template-content">
      <!-- Section -->
      <div class="template-section template-section-padding-1 template-main template-clear-fix">
        <!-- Features list -->
        <div
          class="template-component-feature-list template-component-feature-list-position-left template-clear-fix"
        >
          <!-- Layout 33x33x33% -->
          <ul class="template-layout-33x33x33 template-clear-fix">
            <!-- Left column -->
            <li class="template-layout-column-left">
              <span class="template-icon-feature-phone-circle"></span>
              <h5>Call Us At</h5>
              <p>
                {{ contact.phone_one }}
                <br />
                {{ contact.phone_two }}
                <br />
              </p>
            </li>

            <!-- Center column -->
            <li class="template-layout-column-center">
              <span class="template-icon-feature-location-map"></span>
              <h5>Our Address</h5>
              <p>{{ contact.address}}</p>
            </li>

            <!-- Right column -->
            <li class="template-layout-column-right">
              <span class="template-icon-feature-clock"></span>
              <h5>Working hours</h5>
              <p>{{ contact.working_hours }}</p>
            </li>
          </ul>
        </div>
      </div>

      <!-- Layout Flex -->
      <div class="template-layout-flex template-clear-fix">
        <!-- Left column -->
        <div class="template-padding-reset">
          <!-- Google Map -->
          <div class="template-component-google-map">
            <!-- Content -->
            <div class="template-component-google-map-box">
              <div class="template-component-google-map-box-content"></div>
            </div>
          </div>
        </div>

        <!-- Right column -->
        <div>
          <!-- Header -->
          <div class="template-component-header-subheader template-align-left">
            <h4>{{ contact.form_title }}</h4>
            <div></div>
          </div>

          <!-- Text -->
          <p class="template-padding-reset">{{ contact.form_content }}</p>

          <!-- Space -->
          <div class="template-component-space template-component-space-2"></div>

          <!-- Contact form -->
          <form
            name="contact-form"
            id="contact-form"
            method="POST"
            action="#"
            class="template-component-contact-form"
            @submit.prevent="sendMessage"
          >
            <!-- Layout 50x50% -->
            <ul class="template-layout-50x50 template-layout-margin-reset template-clear-fix">
              <!-- Left column -->
              <li class="template-layout-column-left template-margin-bottom-reset">
                <div class="template-component-form-field template-state-block">
                  <label for="contact-form-name">Your Name *</label>
                  <input
                    type="text"
                    v-model="contactMessageData.name"
                    name="contact-form-name"
                    id="contact-form-name"
                    required
                  />
                </div>
                <div class="template-component-form-field template-state-block">
                  <label for="contact-form-email">Your E-mail *</label>
                  <input
                    type="email"
                    v-model="contactMessageData.email"
                    name="contact-form-email"
                    id="contact-form-email"
                    required
                  />
                </div>
                <div class="template-component-form-field template-state-block">
                  <label for="contact-form-phone">Phone Number</label>
                  <input
                    type="text"
                    v-model="contactMessageData.phone"
                    name="contact-form-phone"
                    id="contact-form-phone"
                    required
                  />
                </div>
              </li>

              <!-- Right column -->
              <li class="template-layout-column-right template-margin-bottom-reset">
                <div class="template-component-form-field template-state-block">
                  <label for="contact-form-message">Message *</label>
                  <textarea
                    rows="1"
                    cols="1"
                    v-model="contactMessageData.message"
                    name="contact-form-message"
                    id="contact-form-message"
                    required
                  ></textarea>
                </div>
              </li>
            </ul>

            <!-- Button -->
            <div class="template-align-center template-clear-fix template-margin-top-1">
              <span class="template-state-block">
                <input
                  type="submit"
                  :value="contact.button_text"
                  class="template-component-button"
                  name="contact-form-submit"
                  id="contact-form-submit"
                />
              </span>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";

export default {
  name: "Contact",
  data() {
    return {
      contact: {},
      contactMessageData: {
        name: "",
        email: "",
        phone: "",
        message: ""
      },
      isLoading: false,
      fullPage: true,
      loader: "bars"
    };
  },
  async mounted() {
    await this.getContact();
    window
      .$(".template-layout-33x33x33")
      .responsiveElement({ className: "template-responsive-column-a" });
    window
      .$(".template-layout-50x50")
      .responsiveElement({ className: "template-responsive-column-a" });
    this.callMap();
  },
  components: {
    Loading
  },
  methods: {
    async getContact() {
      this.isLoading = true;
      let config = {
        method: "get",
        url: this.$apiBaseUrl + this.$api.contact
      };
      await this.$axios(config)
        .then(response => {
          this.contact = response.data.data;
          console.log("contact", response);
          this.isLoading = false;
        })
        .catch(error => {
          console.log(error);
          this.isLoading = false;
        });
    },
    sendMessage() {
      this.isLoading = true;
      let config = {
        method: "post",
        url: this.$apiBaseUrl + this.$api.contactMessage,
        data: this.contactMessageData
      };
      this.$axios(config)
        .then(response => {
          if (response.data.status == false) {
            this.$swal({
              title: response.data.message,
              icon: "error"
            });
          } else {
            this.resetData();
            this.$swal({
              title: response.data.message,
              icon: "success"
            });
          }
          this.isLoading = false;
        })
        .catch(error => {
          console.log(error);
          this.isLoading = false;
        });
    },
    resetData() {
      this.contactMessageData.name = "";
      this.contactMessageData.email = "";
      this.contactMessageData.phone = "";
      this.contactMessageData.message = "";
    },
    callMap() {
        window.$(".template-component-google-map").templateGoogleMap({
          coordinate: {
            lat: "-37.823952",
            lng: "144.958766"
          },
          dimension: {
            width: "100%",
            height: "100%"
          },
          marker: "assets/media/image/map_pointer.png"
        });
    }
  }
};
</script>

<style>
</style>