<template>
  <div>
    <!-- Header -->
    <Header></Header>

    <!-- Content -->
    <router-view></router-view>

    <!-- Footer -->
    <Footer></Footer>

    <!-- Search box -->
    <!-- <div class="template-component-search-form">
      <div></div>
      <form>
        <div>
          <input type="search" name="search" />
          <span class="template-icon-meta-search"></span>
          <input type="submit" name="submit" value />
        </div>
      </form>
    </div>-->

    <!-- Go to top button -->
    <a href="#go-to-top" class="template-component-go-to-top template-icon-meta-arrow-large-tb"></a>

    <!-- Wrapper for date picker -->
    <div id="dtBox"></div>
  </div>
</template>

<script>
import Header from "../components/includes/Header.vue";
import Footer from "../components/includes/Footer.vue";
export default {
  name: "Master",  
  components: {
    Header,
    Footer
  }, 
};
</script>

<style>
</style>