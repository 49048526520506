<template>
  <div>
    <div style="text-align: center;">
      <loading
        :active="isLoading"
        :is-full-page="fullPage"
        :loader="loader"
        :color="color"
        :lock-scroll="lockScroll"
      />
    </div>
    <div class="template-content">
      <!-- Section -->
      <div class="template-section template-section-padding-1 template-clear-fix template-main">
        <!-- Header + subheader -->
        <div class="template-component-header-subheader">
          <h2>{{ packageData.title }}</h2>
          <div></div>
          <span>{{ packageData.sub_title }}</span>
        </div>

        <div class="template-clear-fix template-align-center">
          <!-- Text -->
          <p>{{ packageData.description }}</p>

          <!-- Space -->
          <div class="template-component-space template-component-space-2"></div>

          <!-- Button -->
          <a
            :href="packageData.button_link"
            @click.prevent="this.$router.push(packageData.button_link)"
            class="template-component-button template-align-center"
          >{{ packageData.button_text }}</a>
        </div>

        <!-- Space -->
        <div class="template-component-space template-component-space-2"></div>

        <!-- Divider -->
        <div class="template-component-divider"></div>

        <!-- Space -->
        <div class="template-component-space template-component-space-2"></div>

        <!-- Features list -->
        <div class="template-component-feature-list template-component-feature-list-position-left">
          <!-- Layout 33x33x33% -->
          <ul class="template-layout-33x33x33 template-clear-fix">
            <!-- Left column -->
            <li
              v-for="(item, index) in services"
              :key="index"
              :class="index == 0 ? 'template-layout-column-left' : 
              index == 1 ? 'template-layout-column-center' : 
              index == 2 ? 'template-layout-column-right' : 
              index == 3 ? 'template-layout-column-left' :
              index == 4 ? 'template-layout-column-center' :
              index == 5 ? 'template-layout-column-right' : ''"
            >
              <span :class="item.icon"></span>
              <h5>{{ item.title }}</h5>
              <p>{{ item.description }}</p>
            </li>
          </ul>
        </div>
      </div>

      <!-- Section -->
      <div
        class="template-section template-background-image template-background-image-6 template-background-image-parallax template-color-white template-clear-fix"
      >
        <!-- Main -->
        <div class="template-main">
          <!-- Header + subheader -->
          <div class="template-component-header-subheader">
            <h2>{{ cms.testimonial_section_title }}</h2>
            <div></div>
            <span>{{ cms.testimonial_section_sub_title }}</span>
          </div>

          <!-- Testimonial list -->
          <div class="template-component-testimonial-list template-clear-fix">
            <!-- Content -->
            <ul class="template-clear-fix">
              <li v-for="(item, index) in testimonials" :key="index">
                <p>{{ item.comment }}</p>
                <h6>{{ item.customer_name }}</h6>
                <span>{{ item.customer_company }}</span>
              </li>
            </ul>

            <!-- Mavigation -->
            <div class="template-component-testimonial-list-navigation">
              <a
                href="#"
                class="template-component-testimonial-list-navigation-left template-icon-meta-arrow-large-rl"
              ></a>
              <span
                class="template-component-testimonial-list-navigation-center template-icon-feature-testimonials"
              ></span>
              <a
                href="#"
                class="template-component-testimonial-list-navigation-right template-icon-meta-arrow-large-rl"
              ></a>
            </div>
          </div>
        </div>
      </div>

      <!-- Section -->
      <div class="template-section template-section-padding-reset template-clear-fix">
        <!-- Counter list -->
        <div class="template-component-counter-box-list">
          <!-- Layout 25x25x25x25% -->
          <ul class="template-layout-25x25x25x25 template-layout-margin-reset template-clear-fix">
            <!-- Left column -->
            <li class="template-layout-column-left template-background-color-1">
              <span class="template-value">{{ history.item_one_total }}</span>
              <span :class="history.item_one_icon"></span>
              <h6>{{ history.item_one_title }}</h6>
            </li>

            <!-- Center left column -->
            <li class="template-layout-column-center-left template-background-color-2">
              <span class="template-value">{{ history.item_two_total }}</span>
              <span :class="history.item_two_icon"></span>
              <h6>{{ history.item_two_title }}</h6>
            </li>

            <!-- Center right column -->
            <li class="template-layout-column-center-right template-background-color-1">
              <span class="template-value">{{ history.item_three_total }}</span>
              <span :class="history.item_three_icon"></span>
              <h6>{{ history.item_three_title }}</h6>
            </li>

            <!-- Right column -->
            <li class="template-layout-column-right template-background-color-2">
              <span class="template-value">{{ history.item_four_total }}</span>
              <span :class="history.item_four_icon"></span>
              <h6>{{ history.item_four_title }}</h6>
            </li>
          </ul>
        </div>
      </div>

      <!-- Section -->
      <div class="template-section template-section-padding-1 template-clear-fix template-main">
        <!-- Layout 33x33x33% -->
        <div class="template-layout-33x33x33 template-clear-fix">
          <!-- Left column -->
          <div class="template-layout-column-left">
            <!-- Feature list -->
            <div
              class="template-component-feature-list template-component-feature-list-position-right"
            >
              <ul class="template-layout-100 template-clear-fix">
                <li
                  v-for="(item, index) in features.slice(0, 3)"
                  :key="index"
                  class="template-layout-column-left"
                >
                  <span :class="item.image"></span>
                  <h5>{{ item.title }}</h5>
                  <p>{{ item.description }}</p>
                </li>
              </ul>
            </div>
          </div>

          <!-- Center column -->
          <div class="template-layout-column-center">
            <div class="template-component-image template-component-image-preloader">
              <img
                v-if="cms.feature_section_center_image != undefined"
                :src="this.$imageUrl + cms.feature_section_center_image"
              />
            </div>
          </div>

          <!-- Right column -->
          <div class="template-layout-column-right">
            <!-- Feature list -->
            <div
              class="template-component-feature-list template-component-feature-list-position-left"
            >
              <ul class="template-layout-100 template-clear-fix">
                <li
                  v-for="(item, index) in features.slice(3, 6)"
                  :key="index"
                  class="template-layout-column-left"
                >
                  <span :class="item.image"></span>
                  <h5>{{ item.title }}</h5>
                  <p>{{ item.description }}</p>
                </li>
              </ul>
              <div class="template-component-space template-component-space-2m"></div>
            </div>
          </div>
        </div>
      </div>

      <!-- Google Maps -->
      <div class="template-section template-section-padding-reset template-clear-fix">
        <!-- Google Map -->
        <div class="template-component-google-map">
          <!-- Content -->
          <div class="template-component-google-map-box">
            <div class="template-component-google-map-box-content"></div>
          </div>

          <!-- Button -->
          <a href="javascript:void(0)" class="template-component-google-map-button">
            <span class="template-icon-meta-marker"></span>
            <span class="template-component-google-map-button-label-show">Show Map</span>
            <span class="template-component-google-map-button-label-hide">Hide Map</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";

export default {
  name: "Services",
  data() {
    return {
      cms: {},
      packageData: {},
      services: [],
      testimonials: [],
      history: {},
      features: [],
      isLoading: false,
      fullPage: true,
      loader: "bars"
    };
  },
  components: {
    Loading
  },
  async created() {
    await this.getServiceData();
    window.$(".template-component-testimonial-list").templateTestimonial();
    window.$(".template-component-image").templateImage();

    window
      .$(".template-layout-50x50")
      .responsiveElement({ className: "template-responsive-column-a" });
    window
      .$(".template-layout-33x33x33")
      .responsiveElement({ className: "template-responsive-column-a" });
    window
      .$(
        ".template-layout-25x25x25x25:not(.template-component-counter-box-list>.template-layout-25x25x25x25)"
      )
      .responsiveElement({
        width: 750,
        className: "template-responsive-column-a"
      });
    window
      .$(".template-layout-66x33")
      .responsiveElement({ className: "template-responsive-column-a" });
    window
      .$(".template-layout-33x66")
      .responsiveElement({ className: "template-responsive-column-a" });

    window
      .$(".template-component-counter-box-list>.template-layout-25x25x25x25")
      .responsiveElement({
        width: 940,
        className: "template-responsive-column-a"
      });
  },
  methods: {
    async getServiceData() {
      this.isLoading = true;
      let config = {
        method: "get",
        url: this.$apiBaseUrl + this.$api.service
      };
      await this.$axios(config)
        .then(response => {
          this.cms = response.data.data.cms;
          this.packageData = response.data.data.package;
          this.services = response.data.data.services;
          this.testimonials = response.data.data.testimonials;
          this.history = response.data.data.history;
          this.features = response.data.data.features;
          console.log("service page", response);
          this.isLoading = false;
        })
        .catch(error => {
          console.log(error);
          this.isLoading = false;
        });
    }
  }
};
</script>

<style>
</style>