<template>
  <div>
    <div
      class="template-header"
      :style="{ backgroundImage: 'url(' + this.$imageUrl + bgImage + ')'}"
      :class="this.$route.name != 'Home' ? 'template-header-background' : ''"
    >      
      <!-- Top header -->
      <div class="template-header-top">
        <!-- Logo -->
        <div class="template-header-top-logo">
          <h2 style="color: black;">{{ this.$router.currentRoute.name }}</h2>
          <router-link :to="{path: '/'}" title>
            <img :src="this.$imageUrl + header.logo" alt class="template-logo" />
            <img :src="this.$imageUrl + header.logo" alt class="template-logo template-logo-sticky" />
          </router-link>
        </div>

        <!-- Menu-->
        <div class="template-header-top-menu template-main">
          <nav>
            <!-- Default menu-->
            <div class="template-component-menu-default">
              <ul class="sf-menu">
                <li>
                  <router-link :to="{path: '/'}" @click="closeMenu">Home</router-link>
                </li>
                <li>
                  <router-link :to="{path: '/about'}" @click="closeMenu">About</router-link>
                </li>
                <li>
                  <router-link :to="{path: '/services'}" @click="closeMenu">Services</router-link>
                </li>
                <li>
                  <router-link :to="{path: '/blogs'}" @click="closeMenu">Blogs</router-link>
                </li>
                <li>
                  <router-link :to="{path: '/booking'}" @click="closeMenu">Booking</router-link>
                </li>
                <li>
                  <router-link :to="{path: '/gallery'}" @click="closeMenu">Gallery</router-link>
                </li>
                <li>
                  <router-link :to="{path: '/contact'}" @click="closeMenu">Contact</router-link>
                </li>
              </ul>
            </div>
          </nav>
          <nav>
            <!-- Mobile menu-->
            <div class="template-component-menu-responsive">
              <ul class="flexnav">
                <li>
                  <a href="#">
                    <span
                      class="touch-button template-icon-meta-arrow-large-tb template-component-menu-button-close"
                    ></span>&nbsp;
                  </a>
                </li>
                <li>
                  <router-link :to="{path: '/'}" @click="closeMenu">Home</router-link>
                </li>
                <li>
                  <router-link :to="{path: '/about'}" @click="closeMenu">About</router-link>
                </li>
                <li>
                  <router-link :to="{path: '/services'}" @click="closeMenu">Services</router-link>
                </li>
                <li>
                  <router-link :to="{path: '/blogs'}" @click="closeMenu">Blogs</router-link>
                </li>
                <li>
                  <router-link :to="{path: '/booking'}" @click="closeMenu">Booking</router-link>
                </li>
                <li>
                  <router-link :to="{path: '/gallery'}" @click="closeMenu">Gallery</router-link>
                </li>
                <li>
                  <router-link :to="{path: '/contact'}" @click="closeMenu">Contact</router-link>
                </li>
              </ul>
            </div>
          </nav>
        </div>

        <!-- Social icons -->
        <div class="template-header-top-icon-list template-component-social-icon-list-1">
          <ul class="template-component-social-icon-list">
            <li>
              <a href="#" class="template-icon-meta-menu"></a>
            </li>
          </ul>
        </div>
      </div>

      <div class="template-header-bottom">        
        <!-- Slider -->
        <slider v-if="this.$route.name == 'Home'" />
        <bread-crumb @childClick="getData" v-else />
        <!--/Slider-->
      </div>
    </div>
  </div>
</template>

<script>
import Slider from "./Slider.vue";
import BreadCrumb from "./BreadCrumb.vue";
export default {
  name: "Header",
  data() {
    return {
      header: {},
      bgImage: {}
    };
  },
  components: {
    Slider,
    BreadCrumb
  },
  async created() {
    await this.getHeader();
  },
  methods: {
    getData(value) {
      this.bgImage = value.header_image;
      if (this.bgImage == undefined) {
        this.bgImage = "20220710-62ca9545c0017.jpg";
      } else {
        this.bgImage = value.header_image;
      }
    },
    async getHeader() {
      let config = {
        method: "get",
        url: this.$apiBaseUrl + this.$api.footer
      };
      await this.$axios(config)
        .then(response => {
          this.header = response.data.data.footer;
          console.log("header", response);
        })
        .catch(error => {
          console.log(error);
        });
    },
    closeMenu() {
      var $menuResponsive = $(".template-component-menu-responsive");
      $menuResponsive.css({ height: "auto" });
      var height = parseInt($menuResponsive.actual("outerHeight"), 10);
      $menuResponsive.animate(
        { top: -1 * height },
        {
          duration: typeof duration === "undefined" ? 500 : duration,
          easing: "easeInOutCubic",
          complete: function() {
            $("body").css({ overflow: "auto" });
            $menuResponsive.removeClass("template-state-open");
          }
        }
      );
    }
  }
};
</script>

<style>
</style>