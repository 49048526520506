import moment from "moment";
export default {
    methods: {
        formatDate(d) {
            return moment(d).format("MMMM DD, YYYY");
        },
        getFormatMonthName(d) {
            return moment(d).format("MMM");
        },
        getFormatYear(d) {
            return moment(d).format("YYYY");
        },
        getFormatDay(d) {
            return moment(d).format("DD");
        },
    }
}