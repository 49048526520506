<template>
  <div>
    <div style="text-align: center;">
      <loading
        :active="isLoading"
        :is-full-page="fullPage"
        :loader="loader"
        :color="color"
        :lock-scroll="lockScroll"
      />
    </div>
    <div class="template-content">
      <!-- Section -->
      <div class="template-section template-section-padding-1 template-clear-fix template-main">
        <!-- Header + subheader -->
        <div class="template-component-header-subheader">
          <h2>{{ about.title }}</h2>
          <div></div>
          <span>{{ about.sub_title }}</span>
        </div>

        <!-- Layout 33x66% -->
        <div class="template-layout-33x66 template-clear-fix">
          <!-- Left column -->
          <div class="template-layout-column-left">
            <img :src="this.$imageUrl+about.image" alt />
          </div>

          <!-- Right column -->
          <div class="template-layout-column-right">
            <!-- Text -->
            <p class="template-padding-reset" v-html="about.content"></p>

            <!-- Feature list -->
            <div
              class="template-component-feature-list template-component-feature-list-position-top template-clear-fix"
            >
              <!-- Layout 50x50% -->
              <ul class="template-layout-50x50 template-clear-fix">
                <!-- Left column -->
                <li class="template-layout-column-left template-margin-bottom-reset">
                  <div class="template-component-space template-component-space-2"></div>
                  <span :class="about.service_icon"></span>
                  <h5>{{ about.service_title }}</h5>
                  <ul class="template-component-list">
                    <li v-for="(item, index) in services" :key="index">
                      <span class="template-icon-meta-check"></span>
                      {{ item }}
                    </li>
                  </ul>
                </li>

                <!-- Right column -->
                <li class="template-layout-column-right template-margin-bottom-reset">
                  <div class="template-component-space template-component-space-2"></div>
                  <span :class="about.contact_icon"></span>
                  <h5>{{ about.contact_title }}</h5>
                  <ul class="template-component-list">
                    <li v-for="(item, index) in contact_infos" :key="index">
                      <span class="template-icon-meta-check"></span>
                      {{ item }}
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <!-- Section -->
      <div
        class="template-section template-section-padding-reset template-clear-fix template-background-color-1"
      >
        <!-- Call to action -->
        <div class="template-component-call-to-action">
          <div class="template-main">
            <h3>{{ booking.title }}</h3>
            <a
              :href="booking.button_link"
              @click.prevent="this.$router.push(booking.button_link)"
              class="template-component-button"
            >{{ booking.button_text }}</a>
          </div>
        </div>
      </div>

      <!-- Section -->
      <div
        class="template-section template-background-image template-background-image-5 template-background-image-parallax template-color-white template-clear-fix"
      >
        <!-- Mian -->
        <div class="template-main">
          <!-- Header + subheader -->
          <div class="template-component-header-subheader">
            <h2>{{ process.title }}</h2>
            <div></div>
            <span>{{ process.sub_title }}</span>
          </div>

          <!-- Space -->
          <div class="template-component-space template-component-space-1"></div>

          <!-- Process list -->
          <div class="template-component-process-list template-clear-fix">
            <!-- Layout 25x25x25x25% -->
            <ul class="template-layout-25x25x25x25 template-clear-fix template-layout-margin-reset">
              <!-- Left column -->
              <li class="template-layout-column-left">
                <span :class="process.process_one_icon"></span>
                <h5>{{ process.process_one_name}}</h5>
                <span class="template-icon-meta-arrow-large-rl"></span>
              </li>

              <!-- Center left column -->
              <li class="template-layout-column-center-left">
                <span :class="process.process_two_icon"></span>
                <h5>{{ process.process_two_name}}</h5>
                <span class="template-icon-meta-arrow-large-rl"></span>
              </li>

              <!-- Center right column -->
              <li class="template-layout-column-center-right">
                <span :class="process.process_three_icon"></span>
                <h5>{{ process.process_three_name}}</h5>
                <span class="template-icon-meta-arrow-large-rl"></span>
              </li>

              <!-- Right column -->
              <li class="template-layout-column-right">
                <span :class="process.process_four_icon"></span>
                <h5>{{ process.process_four_name}}</h5>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!-- Section -->
      <div class="template-section template-section-padding-1 template-clear-fix template-main">
        <!-- Header + subheader -->
        <div class="template-component-header-subheader">
          <h2>{{ cms.package_section_title }}</h2>
          <div></div>
          <span>{{ cms.package_section_sub_title }}</span>
        </div>

        <!-- Booking -->
        <div class="template-component-booking" id="template-booking">
          <form>
            <ul>
              <li>
                <!-- Content -->
                <div class="template-component-booking-item-content">
                  <!-- Vehicle list -->
                  <ul class="template-component-booking-vehicle-list">
                    <!-- Vehicle -->
                    <li
                      v-for="(item, index) in vehicle_types"
                      :key="index"
                      :data-id="index"
                      @click="getWashPackages(item.id, index)"
                      :class="selectedVehicle == index ? 'template-state-selected' : ''"
                    >
                      <div>
                        <!-- Icon -->
                        <div :class="item.icon"></div>

                        <!-- Name -->
                        <div>{{ item.name }}</div>
                      </div>
                    </li>
                  </ul>
                </div>
              </li>

              <li>
                <!-- Content -->
                <div class="template-component-booking-item-content">
                  <!-- Package list -->
                  <ul class="template-component-booking-package-list">
                    <!-- Package -->
                    <li
                      v-for="(item, index) in wash_packages"
                      :key="index"
                      :data-id="index"
                      :data-id-vehicle-rel="item.id"
                    >
                      <!-- Header -->
                      <h4 class="template-component-booking-package-name">{{ item.title}}</h4>

                      <!-- Price -->
                      <div class="template-component-booking-package-price">
                        <span class="template-component-booking-package-price-currency">$</span>
                        <span class="template-component-booking-package-price-total">{{ item.price}}</span>
                        <!-- <span class="template-component-booking-package-price-decimal">95</span> -->
                      </div>

                      <!-- Duration -->
                      <div class="template-component-booking-package-duration">
                        <span class="template-icon-booking-meta-duration"></span>
                        <span
                          class="template-component-booking-package-duration-value"
                        >{{ item.time_duration}}</span>
                        <span class="template-component-booking-package-duration-unit">min</span>
                      </div>

                      <!-- Services -->
                      <ul class="template-component-booking-package-service-list">
                        <li
                          v-for="(detailItem, index) in JSON.parse(item.details)"
                          :key="index"
                          :data-id="detailItem.id"
                        >{{ detailItem }}</li>
                      </ul>

                      <!-- Button -->
                      <div class="template-component-button-box">
                        <a
                          @click.prevent="this.$router.push(cms.package_book_button_link)"
                          :href="cms.package_book_button_link"
                          class="template-component-button"
                        >{{ cms.package_book_button_text }}</a>
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </form>
        </div>
      </div>

      <!-- Section -->
      <div class="template-section template-section-padding-reset template-clear-fix">
        <!-- Flex layout 50x50% -->
        <div class="template-layout-flex template-background-color-1 template-clear-fix">
          <!-- Left column -->
          <div
            :style="{'background-image': 'url(' + this.$imageUrl + cms.service_section_left_image + ')'}"
          ></div>

          <!-- Right column -->
          <div class="template-section-padding-1">
            <!-- Features list -->
            <div
              class="template-component-feature-list template-component-feature-list-position-top"
            >
              <!-- Layout 50x50% -->
              <ul class="template-layout-50x50 template-clear-fix">
                <li
                  v-for="(item, index) in homeServices"
                  :key="index"
                  :class="( index & 1 ) ? 'template-layout-column-right' : 'template-layout-column-left'"
                >
                  <span :class="item.icon"></span>
                  <h5>{{ item.title }}{{ index }}</h5>
                  <p>{{ item.description }}</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <!-- Section -->
      <div class="template-section template-clear-fix template-main">
        <!-- Header + subheader -->
        <div class="template-component-header-subheader">
          <h2>{{ cms.projects_section_title }}</h2>
          <div></div>
          <span>{{ cms.projects_section_sub_title }}</span>
        </div>

        <!-- Gallery -->
        <div class="template-component-gallery">
          <!-- Filter buttons list -->
          <ul class="template-component-gallery-filter-list">
            <li>
              <a
                href="#"
                @click.prevent="getProjects(0)"
                class="template-filter-all template-state-selected"
              >All</a>
            </li>
            <li v-for="(item, index) in project_categories" :key="index">
              <a
                href="#"
                @click.prevent="getProjects(item.id)"
                class="template-filter-hand-wash"
              >{{ item.title }}</a>
            </li>
          </ul>

          <!-- Images list -->
          <ul class="template-component-gallery-image-list">
            <!-- Image -->
            <li v-for="(item, index) in projects" :key="index" class="template-filter-hand-wash">
              <div class="template-component-image template-component-image-preloader">
                <!-- Orginal image -->
                <a
                  :href="this.$imageUrl + item.image"
                  class="template-fancybox"
                  data-fancybox-group="gallery-1"
                >
                  <!-- Thumbnail -->
                  <img :src="this.$imageUrl + item.image" />

                  <!-- Image hover -->
                  <span class="template-component-image-hover">
                    <span>
                      <span>
                        <span class="template-component-image-hover-header">{{ item.title }}</span>
                        <span class="template-component-image-hover-divider"></span>
                        <span
                          class="template-component-image-hover-subheader"
                        >{{ item.category.title }}</span>
                      </span>
                    </span>
                  </span>
                </a>

                <!-- Fancybox description -->
                <div class="template-component-image-description">{{ item.description }}</div>
              </div>
            </li>
          </ul>
        </div>

        <!-- Button -->
        <div class="template-align-center">
          <a
            :href="cms.browse_projects_button_link"
            @click.prevent="this.$router.push(cms.browse_projects_button_link)"
            class="template-component-button"
          >{{ cms.browse_projects_button_text }}</a>
        </div>
      </div>

      <!-- Section -->
      <div class="template-section template-section-padding-reset template-clear-fix">
        <!-- Flex layout 50x50% -->
        <div class="template-layout-flex template-clear-fix template-background-color-1">
          <!-- Left column -->
          <div>
            <!-- Header + subheader -->
            <div class="template-component-header-subheader">
              <h2>{{ cms.testimonial_section_title }}</h2>
              <div></div>
              <span>{{ cms.testimonial_section_sub_title }}</span>
            </div>

            <!-- Space -->
            <div class="template-component-space template-component-space-2"></div>

            <!-- Testimonials list -->
            <div class="template-component-testimonial-list template-clear-fix">
              <!-- Content -->
              <ul class="template-clear-fix">
                <li v-for="(item, index) in testimonials" :key="index">
                  <p>{{ item.comment }}</p>
                  <h6>{{ item.customer_name }}</h6>
                  <span>{{ item.customer_company }}</span>
                </li>
              </ul>

              <!-- Navigation -->
              <div class="template-component-testimonial-list-navigation">
                <a
                  href="#"
                  class="template-component-testimonial-list-navigation-left template-icon-meta-arrow-large-rl"
                ></a>
                <span
                  class="template-component-testimonial-list-navigation-center template-icon-feature-testimonials"
                ></span>
                <a
                  href="#"
                  class="template-component-testimonial-list-navigation-right template-icon-meta-arrow-large-rl"
                ></a>
              </div>
            </div>
          </div>

          <!-- Right column -->
          <div class="template-background-image template-background-image-2 template-color-white">
            <!-- Header + subheader -->
            <div class="template-component-header-subheader">
              <h2>{{ cms.blog_section_title }}</h2>
              <div></div>
              <span>{{ cms.blog_section_sub_title }}</span>
            </div>

            <!-- Recent posts list -->
            <ul class="template-component-recent-post">
              <li v-for="(item, index) in recent_blogs" :key="index">
                <router-link :to="'/blog/'+item.slug">
                  <span>{{ item.title }}</span>
                  <span>{{ formatDate(item.created_at) }}</span>
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!-- Section -->
      <div class="template-section template-section-padding-1 template-clear-fix template-main">
        <!-- Features list -->
        <div
          class="template-component-feature-list template-component-feature-list-position-left template-clear-fix"
        >
          <!-- Layout 33x33x33% -->
          <ul class="template-layout-33x33x33 template-clear-fix">
            <!-- Left column -->
            <li class="template-layout-column-left">
              <span class="template-icon-feature-phone-circle"></span>
              <h5>Call Us At</h5>
              <p>
                {{ contact_info.phone_one }}
                <br />
                {{ contact_info.phone_two }}
                <br />
              </p>
            </li>

            <!-- Center column -->
            <li class="template-layout-column-center">
              <span class="template-icon-feature-location-map"></span>
              <h5>Our Address</h5>
              <p>{{ contact_info.address}}</p>
            </li>

            <!-- Right column -->
            <li class="template-layout-column-right">
              <span class="template-icon-feature-clock"></span>
              <h5>Working hours</h5>
              <p style="white-space: pre-line">{{ contact_info.working_hours }}</p>
            </li>
          </ul>
        </div>
      </div>

      <!-- Google Maps -->
      <div class="template-section template-section-padding-reset template-clear-fix">
        <!-- Google Map -->
        <div class="template-component-google-map">
          <!-- Content -->
          <div class="template-component-google-map-box">
            <div class="template-component-google-map-box-content"></div>
          </div>

          <!-- Button -->
          <a href="javascript:void(0)" class="template-component-google-map-button">
            <span class="template-icon-meta-marker"></span>
            <span class="template-component-google-map-button-label-show">Show Map</span>
            <span class="template-component-google-map-button-label-hide">Hide Map</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import mixin from "../../mixin";

export default {
  name: "Home",
  mixins: [mixin],
  data() {
    return {
      cms: {},
      about: {},
      services: {},
      contact_infos: {},
      booking: {},
      process: {},
      vehicle_types: [],
      homeServices: [],
      project_categories: [],
      testimonials: [],
      recent_blogs: [],
      contact_info: {},
      projects: [],
      wash_packages: [],
      selectedVehicle: 0,
      isLoading: false,
      fullPage: true,
      loader: "bars"
    };
  },
  components: {
    Loading
  },
  async created() {
    await this.getHomeData();
    await this.getProjects(0);
    window.$(".template-component-testimonial-list").templateTestimonial();
    window.$(".template-component-image").templateImage();
    window.$(".template-component-gallery").templateGallery();
    window.$(".template-fancybox").templateFancybox();
    window.$(".template-component-more-link").templateMoreLess();

    window
      .$(".template-layout-50x50")
      .responsiveElement({ className: "template-responsive-column-a" });
    window
      .$(".template-layout-33x33x33")
      .responsiveElement({ className: "template-responsive-column-a" });
    window
      .$(
        ".template-layout-25x25x25x25:not(.template-component-counter-box-list>.template-layout-25x25x25x25)"
      )
      .responsiveElement({
        width: 750,
        className: "template-responsive-column-a"
      });
    window
      .$(".template-layout-66x33")
      .responsiveElement({ className: "template-responsive-column-a" });
    window
      .$(".template-layout-33x66")
      .responsiveElement({ className: "template-responsive-column-a" });

    window
      .$(".template-component-counter-box-list>.template-layout-25x25x25x25")
      .responsiveElement({
        width: 940,
        className: "template-responsive-column-a"
      });
  },
  methods: {
    async getHomeData() {
      this.isLoading = true;
      let config = {
        method: "get",
        url: this.$apiBaseUrl + this.$api.home
      };
      await this.$axios(config)
        .then(response => {
          this.cms = response.data.data.cms;
          this.about = response.data.data.about;
          this.services = JSON.parse(response.data.data.about.services);
          this.contact_infos = JSON.parse(
            response.data.data.about.contact_infos
          );
          this.booking = response.data.data.booking;
          this.process = response.data.data.process;
          this.vehicle_types = response.data.data.vehicle_types;
          this.getWashPackages(this.vehicle_types[0].id, 0); //Load this as defualt data
          this.homeServices = response.data.data.services;
          this.project_categories = response.data.data.project_categories;
          this.testimonials = response.data.data.testimonials;
          this.recent_blogs = response.data.data.recent_blogs;
          this.contact_info = response.data.data.contact_info;
          console.log("home", response);
          this.isLoading = false;
        })
        .catch(error => {
          console.log(error);
          this.isLoading = false;
        });
    },
    async getProjects(categoryId) {
      this.isLoading = true;
      let config = {
        method: "get",
        url: this.$apiBaseUrl + this.$api.projects + "?category_id=" + categoryId
      };
      await this.$axios(config)
        .then(response => {
          this.projects = response.data.data;
          window.$(".template-component-gallery").templateGallery();
          window.$(".template-fancybox").templateFancybox();
          window.$(".template-component-more-link").templateMoreLess();
          window.$(".template-component-image").templateImage();
          window.$(".template-component-image").templateImage();
          console.log("prodjects", response);
          this.isLoading = false;
        })
        .catch(error => {
          console.log(error);
          this.isLoading = false;
        });
    },
    async getWashPackages(vehiclTypeId, index) {
      this.isLoading = true;
      //selected vahicle
      this.selectedVehicle = index;

      let config = {
        method: "get",
        url: this.$apiBaseUrl + this.$api.washPackages + "?vehicle_type_id=" + vehiclTypeId
      };
      await this.$axios(config)
        .then(response => {
          this.wash_packages = response.data.data;
          console.log("washPackages", response);
          this.isLoading = false;
        })
        .catch(error => {
          console.log(error);
          this.isLoading = false;
        });
    }
  }
};
</script>

<style>
</style>