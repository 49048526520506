<template>
  <div>
    <div style="text-align: center;">
      <loading
        :active="isLoading"
        :is-full-page="fullPage"
        :loader="loader"
        :color="color"
        :lock-scroll="lockScroll"
      />
    </div>
    <div class="template-content">
      <!-- Section -->
      <div class="template-component-booking template-section template-main" id="template-booking">
        <!-- Booking form -->
        <form @submit.prevent="submitBooking">
          <ul>
            <!-- Vehcile list -->

            <li>
              <!-- Step -->
              <div class="template-component-booking-item-header template-clear-fix">
                <span>
                  <span>1</span>
                  <span>/</span>
                  <span>4</span>
                </span>
                <h3>Vehicle type</h3>
                <h5>Select vehicle type below.</h5>
              </div>

              <!-- Content -->
              <div class="template-component-booking-item-content">
                <!-- Vehicle list -->
                <ul class="template-component-booking-vehicle-list">
                  <!-- Vehicle -->
                  <li
                    v-for="(item, index) in vehicle_types"
                    :key="index"
                    :data-id="index"
                    @click="getWashPackages(item.id, index)"
                    :class="selectedVehicle == index ? 'template-state-selected' : ''"
                  >
                    <div>
                      <!-- Icon -->
                      <div :class="item.icon"></div>

                      <!-- Name -->
                      <div>{{ item.name }}</div>
                    </div>
                  </li>
                </ul>
              </div>
            </li>
            <!-- Package list -->

            <li>
              <!-- Step -->
              <div class="template-component-booking-item-header template-clear-fix">
                <span>
                  <span>2</span>
                  <span>/</span>
                  <span>4</span>
                </span>
                <h3>Wash packages</h3>
                <h5>Which wash is best for your vehicle?</h5>
              </div>

              <!-- Content -->
              <div class="template-component-booking-item-content">
                <!-- Package list -->
                <ul class="template-component-booking-package-list">
                  <!-- Package -->
                  <li
                    v-for="(item, index) in wash_packages"
                    :key="index"
                    :data-id="index"
                    :data-id-vehicle-rel="item.id"
                    :class="(selectedPackage == item.id && showPackageSelected) ? 'template-state-selected' : ''"
                  >
                    <!-- Header -->
                    <h4 class="template-component-booking-package-name">{{ item.title}}</h4>

                    <!-- Price -->
                    <div class="template-component-booking-package-price">
                      <span class="template-component-booking-package-price-currency">$</span>
                      <span class="template-component-booking-package-price-total">{{ item.price}}</span>
                      <!-- <span class="template-component-booking-package-price-decimal">95</span> -->
                    </div>

                    <!-- Duration -->
                    <div class="template-component-booking-package-duration">
                      <span class="template-icon-booking-meta-duration"></span>
                      <span
                        class="template-component-booking-package-duration-value"
                      >{{ item.time_duration}}</span>
                      <span class="template-component-booking-package-duration-unit">min</span>
                    </div>

                    <!-- Services -->
                    <ul class="template-component-booking-package-service-list">
                      <li
                        v-for="(detailItem, index) in JSON.parse(item.details)"
                        :key="index"
                        :data-id="detailItem.id"
                      >{{ detailItem }}</li>
                    </ul>

                    <!-- Button -->
                    <div class="template-component-button-box">
                      <a
                        href="#"
                        @click.prevent="selectWashPackage(item.id)"
                        class="template-component-button"
                      >Book Now</a>
                    </div>
                  </li>
                </ul>
              </div>
            </li>
            <!-- Service list -->

            <li>
              <!-- Step -->
              <div class="template-component-booking-item-header template-clear-fix">
                <span>
                  <span>3</span>
                  <span>/</span>
                  <span>4</span>
                </span>
                <h3>Services menu</h3>
                <h5>A la carte services menu.</h5>
              </div>

              <!-- Content -->
              <div class="template-component-booking-item-content">
                <!-- Service list -->
                <ul class="template-component-booking-service-list">
                  <!-- Service -->
                  <li
                    v-for="(item, index) in service_menus"
                    :key="index"
                    data-id="exterior-vinyl-protectant"
                    class="template-clear-fix"
                    :class="selectedMenu.includes(item.id) ? 'template-state-selected' : ''"
                  >
                    <div class="template-component-booking-service-name">
                      <span>{{ item.title }}</span>
                    </div>
                    <div class="template-component-booking-service-duration">
                      <span class="template-icon-booking-meta-duration"></span>
                      <span
                        class="template-component-booking-service-duration-value"
                      >{{ item.time_duration }}</span>
                      <span class="template-component-booking-service-duration-unit">min</span>
                    </div>
                    <div class="template-component-booking-service-price">
                      <span class="template-icon-booking-meta-price"></span>
                      <span class="template-component-booking-service-price-currency">$</span>
                      <span class="template-component-booking-service-price-value">{{ item.price }}</span>
                    </div>
                    <div class="template-component-button-box">
                      <a
                        href="#"
                        @click.prevent="selectServiceMenu(item.id)"
                        class="template-component-button"
                      >Select</a>
                    </div>
                  </li>
                </ul>
              </div>
            </li>
            <!-- Summary -->

            <li>
              <!-- Step -->
              <div class="template-component-booking-item-header template-clear-fix">
                <span>
                  <span>4</span>
                  <span>/</span>
                  <span>4</span>
                </span>
                <h3>Booking summary</h3>
                <h5>Please provide us with your contact information.</h5>
              </div>

              <!-- Content -->
              <div class="template-component-booking-item-content">
                <ul class="template-component-booking-summary template-clear-fix">
                  <!-- Duration -->
                  <li class="template-component-booking-summary-duration">
                    <div class="template-icon-booking-meta-total-duration"></div>
                    <h5>
                      <span>0</span>
                      <span>h</span>
                      &nbsp;
                      <span>0</span>
                      <span>min</span>
                    </h5>
                    <span>Duration</span>
                  </li>

                  <!-- Price -->
                  <li class="template-component-booking-summary-price">
                    <div class="template-icon-booking-meta-total-price"></div>
                    <h5>
                      <span class="template-component-booking-summary-price-symbol">$</span>
                      <span class="template-component-booking-summary-price-value">0.00</span>
                    </h5>
                    <span>Total Price</span>
                  </li>
                </ul>
              </div>

              <!-- Content -->
              <div class="template-component-booking-item-content template-margin-top-reset">
                <!-- Layout -->
                <ul class="template-layout-50x50 template-layout-margin-reset template-clear-fix">
                  <!-- First name -->
                  <li class="template-layout-column-left template-margin-bottom-reset">
                    <div class="template-component-form-field">
                      <label for="booking-form-first-name">First Name *</label>
                      <input
                        required
                        v-model="first_name"
                        type="text"
                        name="booking-form-first-name"
                        id="booking-form-first-name"
                      />
                    </div>
                  </li>

                  <!-- Second name -->
                  <li class="template-layout-column-right template-margin-bottom-reset">
                    <div class="template-component-form-field">
                      <label for="booking-form-second-name">Second Name *</label>
                      <input
                        required
                        v-model="second_name"
                        type="text"
                        name="booking-form-second-name"
                        id="booking-form-second-name"
                      />
                    </div>
                  </li>
                </ul>

                <!-- Layout -->
                <ul class="template-layout-50x50 template-layout-margin-reset template-clear-fix">
                  <!-- E-mail address -->
                  <li class="template-layout-column-left template-margin-bottom-reset">
                    <div class="template-component-form-field">
                      <label for="booking-form-email">E-mail Address *</label>
                      <input
                        type="email"
                        name="booking-form-email"
                        id="booking-form-email"
                        required
                        v-model="email"
                      />
                    </div>
                  </li>

                  <!-- Phone number -->
                  <li class="template-layout-column-right template-margin-bottom-reset">
                    <div class="template-component-form-field">
                      <label for="booking-form-phone">Phone Number *</label>
                      <input
                        type="text"
                        name="booking-form-phone"
                        id="booking-form-phone"
                        required
                        v-model="phone"
                      />
                    </div>
                  </li>
                </ul>

                <!-- Layout -->
                <ul
                  class="template-layout-33x33x33 template-layout-margin-reset template-clear-fix"
                >
                  <!-- Vehicle make -->
                  <li class="template-layout-column-left template-margin-bottom-reset">
                    <div class="template-component-form-field">
                      <label for="booking-form-vehicle-make">Vehicle Make</label>
                      <input
                        type="text"
                        required
                        v-model="vehicle_make"
                        name="booking-form-vehicle-make"
                        id="booking-form-vehicle-make"
                      />
                    </div>
                  </li>

                  <!-- Vehicle model -->
                  <li class="template-layout-column-center template-margin-bottom-reset">
                    <div class="template-component-form-field">
                      <label for="booking-form-vehicle-model">Vehicle Model</label>
                      <input
                        type="text"
                        required
                        v-model="vehicle_model"
                        name="booking-form-vehicle-model"
                        id="booking-form-vehicle-model"
                      />
                    </div>
                  </li>

                  <!-- Booking date -->
                  <li class="template-layout-column-right template-margin-bottom-reset">
                    <div class="template-component-form-field">
                      <label>Booking Date *</label>
                      <input type="text" required v-model="booking_date" />
                    </div>
                  </li>
                </ul>

                <!-- Layout -->
                <ul class="template-layout-100 template-layout-margin-reset template-clear-fix">
                  <!-- Message -->
                  <li>
                    <div class="template-component-form-field">
                      <label for="booking-form-message">Message *</label>
                      <textarea
                        rows="1"
                        cols="1"
                        name="booking-form-message"
                        id="booking-form-message"
                        required
                        v-model="message"
                      ></textarea>
                    </div>
                  </li>
                </ul>

                <!-- Text + submit button -->
                <div class="template-align-center template-clear-fix template-margin-top-2">
                  <p
                    class="template-padding-reset template-margin-bottom-2"
                  >We will confirm your appointment with you by phone or e-mail within 24 hours of receiving your request.</p>
                  <input
                    type="submit"
                    value="Confirm Booking"
                    class="template-component-button"
                    name="booking-form-submit"
                    id="booking-form-submit"
                  />
                  <input type="hidden" value name="booking-form-data" id="booking-form-data" />
                </div>
              </div>
            </li>
          </ul>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";

export default {
  name: "Booking",
  data() {
    return {
      cms: {},
      vehicle_types: [],
      service_menus: [],
      wash_packages: [],
      selectedVehicle: 0,
      selectedPackage: "",
      showPackageSelected: false,
      selectedMenu: [],
      showMenuSelected: false,

      first_name: "",
      second_name: "",
      email: "",
      phone: "",
      vehicle_make: "",
      vehicle_model: "",
      total_price: "",
      total_duration: "",
      booking_date: "",
      vehicle_type_id: "",
      wash_package_id: "",
      message: "",
      isLoading: false,
      fullPage: true,
      loader: "bars"
    };
  },
  components: {
    Loading
  },
  async created() {
    await this.getBookingData();

    window
      .$(".template-layout-50x50")
      .responsiveElement({ className: "template-responsive-column-a" });
    window
      .$(".template-layout-33x33x33")
      .responsiveElement({ className: "template-responsive-column-a" });
    window
      .$(
        ".template-layout-25x25x25x25:not(.template-component-counter-box-list>.template-layout-25x25x25x25)"
      )
      .responsiveElement({
        width: 750,
        className: "template-responsive-column-a"
      });
    window
      .$(".template-layout-66x33")
      .responsiveElement({ className: "template-responsive-column-a" });
    window
      .$(".template-layout-33x66")
      .responsiveElement({ className: "template-responsive-column-a" });

    window
      .$(".template-component-counter-box-list>.template-layout-25x25x25x25")
      .responsiveElement({
        width: 940,
        className: "template-responsive-column-a"
      });
  },
  methods: {
    async getBookingData() {
      this.isLoading = true;
      let config = {
        method: "get",
        url: this.$apiBaseUrl + this.$api.bookingPage
      };
      await this.$axios(config)
        .then(response => {
          this.cms = response.data.data.cms;
          this.vehicle_types = response.data.data.vehicle_types;
          this.getWashPackages(this.vehicle_types[0].id, 0);
          this.service_menus = response.data.data.service_menus;
          console.log("booking Page", response);
          this.isLoading = false;
        })
        .catch(error => {
          console.log(error);
          this.isLoading = false;
        });
    },
    async getWashPackages(vehiclTypeId, index) {
      this.isLoading = true;
      //selected vahicle
      this.selectedVehicle = index;
      this.selectedPackage = "";
      this.showPackageSelected = false;
      this.vehicle_type_id = vehiclTypeId;

      let config = {
        method: "get",
        url: this.$apiBaseUrl + this.$api.washPackages + "?vehicle_type_id=" + vehiclTypeId
      };
      await this.$axios(config)
        .then(response => {
          this.wash_packages = response.data.data;
          console.log("washPackages", response);
          this.isLoading = false;
        })
        .catch(error => {
          console.log(error);
          this.isLoading = false;
        });

      this.resetData();
    },
    resetData() {
      $(".template-component-booking-summary-price-value").text("0.00");
      $(".template-component-booking-summary-duration>h5>span:eq(0)").text(0);
      $(".template-component-booking-summary-duration>h5>span:eq(2)").text(0);
    },
    addSelectedClass(index) {
      this.selectedPackage = index;
      if (this.selectedPackage == index) {
        this.showPackageSelected = !this.showPackageSelected;
      }
    },
    addMenuSelectedClass(itemId) {
      if (this.selectedMenu.includes(itemId)) {
        this.selectedMenu.splice(this.selectedMenu.indexOf(itemId), 1);
      } else {
        this.selectedMenu.push(itemId);
      }
    },
    async selectWashPackage(index) {
      this.wash_package_id = index;
      await this.addSelectedClass(index);
      await this.calculate();
    },
    async selectServiceMenu(itemId) {
      await this.addMenuSelectedClass(itemId);
      await this.calculate();
    },
    calculateDuration(minute) {
      if (minute < 60) return [0, minute];
      return [Math.floor(minute / 60), minute - Math.floor(minute / 60) * 60];
    },
    calculate() {
      var cost = 0.0;
      var duration = 0;

      var packageSelected = $(
        ".template-component-booking-package-list>li.template-state-selected"
      );

      console.log("packageSelected", packageSelected);

      if (packageSelected.length === 1) {
        cost += parseFloat(
          packageSelected
            .find(".template-component-booking-package-price-total")
            .text()
        );
        duration += parseInt(
          packageSelected
            .find(".template-component-booking-package-duration-value")
            .text(),
          10
        );
      }

      var serviceSelected = $(
        ".template-component-booking-service-list>li.template-state-selected"
      );

      // console.log("serviceSelected", serviceSelected);
      for(var i =0;i < serviceSelected.length;i++) {
        const dom = serviceSelected[i];
        console.log(dom);
        cost = parseFloat(cost);
        duration = parseInt(duration);
        let value1= dom.querySelector(".template-component-booking-service-price-value").innerHTML;
        value1 = parseFloat(value1.trim(" "));
        cost = cost + value1;
        let value2= dom.querySelector(".template-component-booking-service-duration-value").innerHTML;
        value2 = parseFloat(value2.trim(" "));
        duration = duration + value2;
      }
      // serviceSelected.each(function() {
      //   cost = parseFloat(cost);
      //   duration = parseInt(duration);
      //   let value1= serviceSelected.find(".template-component-booking-service-price-value").text();
      //   value1 = parseFloat(value1)
      //   console.log("Price Value",value1, "Type of value1: "+typeof value1,"Type of cost: "+typeof cost,"================================================");
      //   cost=cost + value1;
      //   let value2 = serviceSelected.find(".template-component-booking-service-duration-value").text();
      //   value2 = parseInt(value2)
      //   console.log("Duration value",value2, "Type of value1: "+typeof value2,"Type of duration: "+typeof duration,"================================================================");
      //   duration = duration + value2;
      // });

      duration = this.calculateDuration(parseInt(duration, 10));

      $(".template-component-booking-summary-price-value").text(
        parseFloat(cost).toFixed(2)
      );

      $(".template-component-booking-summary-duration>h5>span:eq(0)").text(
        duration[0]
      );
      $(".template-component-booking-summary-duration>h5>span:eq(2)").text(
        duration[1]
      );

      this.total_price = parseFloat(cost).toFixed(2);
      this.total_duration = duration[0] + " Hour " + duration[1] + " Min";
    },
    submitBooking() {
      this.isLoading = true;
      let data = {
        first_name: this.first_name,
        second_name: this.second_name,
        email: this.email,
        phone: this.phone,
        vehicle_make: this.vehicle_make,
        vehicle_model: this.vehicle_model,
        total_price: this.total_price,
        total_duration: this.total_duration,
        booking_date: this.booking_date,
        vehicle_type_id: this.vehicle_type_id,
        wash_package_id: this.wash_package_id,
        services_menu_ids: this.selectedMenu,
        message: this.message
      };
      console.log("data", data);
      let config = {
        method: "post",
        url: this.$apiBaseUrl + this.$api.booking,
        data: data
      };
      this.$axios(config)
        .then(response => {
          if (response.data.status == false) {
            this.$swal({
              title: response.data.message,
              icon: "error"
            });
          } else {
            this.$swal({
              title: response.data.message,
              icon: "success"
            });
            this.first_name = "";
            this.second_name = "";
            this.email = "";
            this.phone = "";
            this.vehicle_make = "";
            this.vehicle_model = "";
            this.total_price = "";
            this.total_duration = "";
            this.booking_date = "";
            this.vehicle_type_id = "";
            this.wash_package_id = "";
            this.selectedMenu = [];
            this.message = "";
          }
          this.isLoading = false;
        })
        .catch(error => {
          console.log(error);
          this.isLoading = false;
        });
    }
  }
};
</script>

<style>
</style>