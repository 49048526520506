import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/pages/Home';
import About from '../views/pages/About';
import Services from '../views/pages/Services';
import Blogs from '../views/pages/Blogs';
import Gallery from '../views/pages/Gallery';
import Booking from '../views/pages/Booking';
import Contact from '../views/pages/Contact';
import SingleBlog from '../views/pages/SingleBlog';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/services',
    name: 'Services',
    component: Services
  },
  {
    path: '/blogs',
    name: 'Blogs',
    component: Blogs
  },  
  {
    path: '/gallery',
    name: 'Gallery',
    component: Gallery
  },
  {
    path: '/booking',
    name: 'Booking',
    component: Booking
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/blog/:slug',
    name: 'SingleBlog',
    component: SingleBlog
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "template-state-selected"
})

export default router
