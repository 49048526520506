<template>
  <div>
    <div style="text-align: center;">
      <loading
        :active="isLoading"
        :is-full-page="fullPage"
        :loader="loader"
        :color="color"
        :lock-scroll="lockScroll"
      />
    </div>
    <div class="template-content">
      <!-- Section -->
      <div class="template-section template-section-padding-1 template-main template-align-center">
        <!-- Gallery -->
        <div class="template-component-gallery">
          <!-- Filter buttons list -->
          <ul class="template-component-gallery-filter-list">
            <li>
              <a
                href="#"
                @click.prevent="getProjects(0)"
                class="template-filter-all template-state-selected"
              >All</a>
            </li>
            <li v-for="(item, index) in project_categories" :key="index">
              <a
                href="#"
                @click.prevent="getProjects(item.id)"
                class="template-filter-hand-wash"
              >{{ item.title }}</a>
            </li>
          </ul>

          <!-- Images list -->
          <ul class="template-component-gallery-image-list">
            <!-- Image -->
            <li v-for="(item, index) in projects" :key="index" class="template-filter-hand-wash">
              <div class="template-component-image template-component-image-preloader">
                <!-- Orginal image -->
                <a
                  :href="this.$imageUrl + item.image"
                  class="template-fancybox"
                  data-fancybox-group="gallery-1"
                >
                  <!-- Thumbnail -->
                  <img :src="this.$imageUrl + item.image" />

                  <!-- Image hover -->
                  <span class="template-component-image-hover">
                    <span>
                      <span>
                        <span class="template-component-image-hover-header">{{ item.title }}</span>
                        <span class="template-component-image-hover-divider"></span>
                        <span
                          class="template-component-image-hover-subheader"
                        >{{ item.category.title }}</span>
                      </span>
                    </span>
                  </span>
                </a>

                <!-- Fancybox description -->
                <div class="template-component-image-description">
                  Suspendisse sagittis placerat diam sit amet viverra neque elementum et. Donec lacinia in tortor ac tristique.
                  In dui leo bibendum et dignissim non laoreet ut nulla. Nunc vulputate odio a dapibus feugiat tortor velit iaculis libero
                  sit amet euismod lacus elit et enim. Aliquam semper nunc sed rhoncus interdum.
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <!-- Google Maps -->
      <div class="template-section template-section-padding-reset template-clear-fix">
        <!-- Google Map -->
        <div class="template-component-google-map">
          <!-- Content -->
          <div class="template-component-google-map-box">
            <div class="template-component-google-map-box-content"></div>
          </div>

          <!-- Button -->
          <a href="javascript:void(0)" class="template-component-google-map-button">
            <span class="template-icon-meta-marker"></span>
            <span class="template-component-google-map-button-label-show">Show Map</span>
            <span class="template-component-google-map-button-label-hide">Hide Map</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";

export default {
  name: "Gallery",
  data() {
    return {
      project_categories: [],
      projects: [],
      isLoading: false,
      fullPage: true,
      loader: "bars"
    };
  },
  components: {
    Loading
  },
  async created() {
    await this.getProjectCategories();
    await this.getProjects(0);
    window.$(".template-component-gallery").templateGallery();
    window.$(".template-fancybox").templateFancybox();
    window.$(".template-component-more-link").templateMoreLess();
    window.$(".template-component-image").templateImage();
  },
  methods: {
    async getProjectCategories() {
      this.isLoading = true;
      let config = {
        method: "get",
        url: this.$apiBaseUrl + this.$api.projectCategories
      };
      await this.$axios(config)
        .then(response => {
          this.project_categories = response.data.data;
          console.log("projectCategories", response);
          this.isLoading = false;
        })
        .catch(error => {
          console.log(error);
          this.isLoading = false;
        });
    },
    async getProjects(categoryId) {
      this.isLoading = true;
      let config = {
        method: "get",
        url:
          this.$apiBaseUrl + this.$api.projects + "?category_id=" + categoryId
      };
      await this.$axios(config)
        .then(response => {
          this.projects = response.data.data;
          console.log("prodjects", response);
          this.isLoading = false;
        })
        .catch(error => {
          console.log(error);
          this.isLoading = false;
        });
    }
  }
};
</script>

<style>
</style>