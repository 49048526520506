import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

const app = createApp(App);

app.config.globalProperties.$axios = axios;
app.config.globalProperties.$api = require('./config/api.json');
//app.config.globalProperties.$apiBaseUrl = "http://localhost/neutrix/utica-backend/public/";
app.config.globalProperties.$apiBaseUrl = "https://autospaadmin.1space.co/";
app.config.globalProperties.$imageUrl = "https://autospaadmin.1space.co/uploads/images/";

app.use(store).use(router).use(VueAxios, axios).use(VueSweetalert2).mount('#app')
