<template>
  <div>
    <div style="text-align: center;">
      <loading
        :active="isLoading"
        :is-full-page="fullPage"
        :loader="loader"
        :color="color"
        :lock-scroll="lockScroll"
      />
    </div>
    <div class="template-content">
      <!-- Main -->
      <div class="template-section template-main">
        <!-- Layout -->
        <div
          class="template-content-layout template-content-layout-sidebar-left template-clear-fix"
        >
          <!-- Left column -->
          <div class="template-content-layout-column-left">
            <!-- Widgets list -->
            <ul class="template-widget-list">
              <!-- Widget -->
              <li>
                <div class="template-widget">
                  <!-- Widget header -->
                  <h6>Search Blog</h6>

                  <!-- Search widget -->
                  <div class="template-widget-search">
                    <div class="template-component-search-form">
                      <form @submit.prevent="searchBlog">
                        <div>
                          <input type="search" v-model="keyword" @input="searchBlog" required />
                          <span class="template-icon-meta-search"></span>
                          <input type="submit" style="color: transparent" />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </li>

              <!-- Widget -->
              <li>
                <div class="template-widget">
                  <h6>Latest Posts</h6>

                  <!-- Latest post widget -->
                  <div class="template-widget-latest-post">
                    <ul>
                      <li v-for="(item, index) in latest_blogs" :key="index">
                        <router-link :to="'/blog/' + item.slug">
                          <img
                            v-if="item.thumbnail != null"
                            style="width: 100px;height: 100px;"
                            :src="this.$imageUrl + item.thumbnail"
                          />
                          <span>{{ item.title }}</span>
                          <span class="template-icon-meta-time">
                            <span>{{ formatDate(item.created_at) }}</span>
                          </span>
                        </router-link>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>

              <!-- Widget -->
              <li>
                <div class="template-widget">
                  <h6>Most commented</h6>

                  <!-- Most commented widget -->
                  <div class="template-widget-most-comment">
                    <ul>
                      <li v-for="(item, index) in most_commented_posts" :key="index">
                        <router-link :to="'/blog/' + item.slug">
                          <img
                            style="width: 100px; height: 100px;"
                            v-if="item.thumbnail != null"
                            :src="this.$imageUrl + item.thumbnail"
                          />
                          <span>{{ item.title }}</span>
                          <span class="template-icon-meta-comment">
                            <span>{{ item.total_comment }} Comments</span>
                          </span>
                        </router-link>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>

              <!-- Widget -->
              <li>
                <div class="template-widget">
                  <h6>Categories</h6>

                  <!-- Categories widget -->
                  <div class="template-widget-category">
                    <ul>
                      <li v-for="(item, index) in categories" :key="index">
                        <a href="#">
                          <span>{{ item.title }}</span>
                          <span>{{ item.total }}</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>

              <!-- Widget -->
              <li>
                <div class="template-widget">
                  <h6>Popular Tags</h6>

                  <!-- Popular tags widget -->
                  <div class="template-widget-tag">
                    <ul v-if="popular_tags.tags">
                      <li v-for="(item, index) in popular_tags.tags.split(',')" :key="index">
                        <a href="javascript:void(0)">{{ item }}</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>

              <!-- Widget -->
              <li>
                <div class="template-widget">
                  <h6>Archives</h6>

                  <!-- Archive widget -->
                  <div class="template-widget-archive">
                    <ul>
                      <li v-for="(item, index) in archive_posts" :key="index">
                        <a>
                          <span>{{ item.month_name }}</span>
                          <span>{{ item.total_post }}</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <!-- Right column -->
          <div class="template-content-layout-column-right">
            <div class="template-blog template-blog-style-1" v-if="all_blogs.data.length > 0">
              <ul>
                <!-- Post -->
                <li v-for="(item, index) in all_blogs.data" :key="index">
                  <!-- Header -->
                  <div class="template-blog-header">
                    <!-- Date -->
                    <div>
                      <div class="template-blog-header-date">
                        <span>{{ getFormatMonthName(item.created_at) }}</span>
                        <span>{{ getFormatDay(item.created_at) }}</span>
                        <span>{{ getFormatYear(item.created_at) }}</span>
                      </div>
                    </div>

                    <!-- Header + meta -->
                    <div>
                      <!-- Header -->
                      <h3 class="template-blog-header-header">
                        <router-link :to="'/blog/' + item.slug">{{ item.title }}</router-link>
                      </h3>

                      <!-- Meta -->
                      <div class="template-blog-header-meta">
                        <span class="template-icon-meta-user">
                          <router-link :to="'/blog/' + item.slug">{{ item.author_name }}</router-link>
                        </span>
                        <span class="template-icon-meta-category">
                          <router-link :to="'/blog/' + item.slug">{{ item.category.title }}</router-link>
                        </span>
                        <span class="template-icon-meta-comment">
                          <router-link :to="'/blog/' + item.slug">{{ item.comments.length }}</router-link>
                        </span>
                      </div>
                    </div>
                  </div>

                  <!-- Image -->
                  <div class="template-blog-image">
                    <div class="template-component-image template-component-image-preloader">
                      <router-link :to="'/blog/' + item.slug">
                        <img v-if="item.thumbnail != null" :src="this.$imageUrl + item.thumbnail" />
                        <span class="template-component-image-hover"></span>
                      </router-link>
                    </div>
                  </div>

                  <!-- Excerpt -->
                  <div class="template-blog-excerpt">
                    <p>{{ item.short_description }}</p>
                    <router-link
                      :to="'/blog/' + item.slug"
                      class="template-component-button"
                    >Read More</router-link>
                  </div>
                </li>
              </ul>

              <!-- Pagination -->
              <div class="template-pagination template-clear-fix">
                <ul>
                  <li
                    v-for="(item, index) in all_blogs.links"
                    :key="index"
                    :class="item.active ? 'template-pagination-button-selected' : index == 0 ? 'template-pagination-button-prev' : index == (all_blogs.links.length - 1) ? 'template-pagination-button-next' : ''"
                  >
                    <a
                      @click.prevent="paginateData(item.url)"
                      href="javascript:void(0)"
                      :class="item.url != null ? '' : 'disable_a_tag'"
                      class="template-icon-meta-arrow-large-rl"
                      v-if="index == 0"
                    >&nbsp;</a>
                    <a
                      @click.prevent="paginateData(item.url)"
                      href="javascript:void(0)"
                      :class="item.url != null ? '' : 'disable_a_tag'"
                      v-else-if="index != (all_blogs.links.length - 1) && item.url != null"
                    >{{ item.label }}</a>
                    <a
                      @click.prevent="paginateData(item.url)"
                      href="javascript:void(0)"
                      :class="item.url != null ? '' : 'disable_a_tag'"
                      class="template-icon-meta-arrow-large-rl"
                      v-else-if="index == (all_blogs.links.length - 1)"
                    >&nbsp;</a>
                  </li>
                </ul>
              </div>
            </div>

            <p v-if="all_blogs.data.length == 0" class="text-center">No Data Found!</p>
          </div>
        </div>
      </div>

      <!-- Google Maps -->
      <div class="template-section template-section-padding-reset template-clear-fix">
        <!-- Google Map -->
        <div class="template-component-google-map">
          <!-- Content -->
          <div class="template-component-google-map-box">
            <div class="template-component-google-map-box-content"></div>
          </div>

          <!-- Button -->
          <a href="javascript:void(0)" class="template-component-google-map-button">
            <span class="template-icon-meta-marker"></span>
            <span class="template-component-google-map-button-label-show">Show Map</span>
            <span class="template-component-google-map-button-label-hide">Hide Map</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import mixin from "../../mixin";

export default {
  name: "Blogs",
  data() {
    return {
      cms: {},
      all_blogs: {},
      popular_tags: {},
      latest_blogs: [],
      categories: [],
      most_commented_posts: [],
      archive_posts: [],
      keyword: "",
      isLoading: false,
      fullPage: true,
      loader: "bars"
    };
  },
  mixins: [mixin],
  components: {
    Loading
  },
  async created() {
    await this.getBlogData();
    window.$(".template-component-image").templateImage();
  },
  methods: {
    async getBlogData() {
      this.isLoading = true;
      let config = {
        method: "get",
        url: this.$apiBaseUrl + this.$api.blogPage
      };
      await this.$axios(config)
        .then(response => {
          this.cms = response.data.data.cms;
          this.all_blogs = response.data.data.all_blogs;
          this.latest_blogs = response.data.data.latest_blogs;
          this.popular_tags = response.data.data.popular_tags;
          this.categories = response.data.data.categories;
          this.most_commented_posts = response.data.data.most_commented_posts;
          this.archive_posts = response.data.data.archive_posts;
          console.log("Blog Data", response);
          this.isLoading = false;
        })
        .catch(error => {
          console.log(error);
          this.isLoading = false;
        });
    },
    async paginateData(url) {
      this.isLoading = true;
      let config = {
        method: "get",
        url: url
      };
      await this.$axios(config)
        .then(response => {
          this.all_blogs = response.data.data.all_blogs;
          console.log("Paginate Blog Data", response);
          this.isLoading = false;
        })
        .catch(error => {
          console.log(error);
          this.isLoading = false;
        });
    },
    async searchBlog() {
      if (this.keyword == "") {
        await this.getBlogData();
      } else {
        this.isLoading = true;
        let config = {
          method: "get",
          url: this.$apiBaseUrl + this.$api.blogSearch,
          params: {
            keyword: this.keyword
          }
        };
        await this.$axios(config)
          .then(response => {
            this.all_blogs = response.data.data.all_blogs;
            console.log("Search Blog Data", response);
            this.isLoading = false;
          })
          .catch(error => {
            console.log(error);
            this.isLoading = false;
          });
      }
      window.$(".template-component-image").templateImage();
    }
  }
};
</script>

<style scoped>
.disable_a_tag {
  pointer-events: none;
  cursor: not-allowed;
}
</style>