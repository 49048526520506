<template>
  <div>
    <div style="text-align: center;">
      <loading
        :active="isLoading"
        :is-full-page="fullPage"
        :loader="loader"
        :color="color"
        :lock-scroll="lockScroll"
      />
    </div>
    <div class="template-content">
      <!-- Main -->
      <div class="template-section template-main">
        <!-- Layout -->
        <div
          class="template-content-layout template-content-layout-sidebar-left template-clear-fix"
        >
          <!-- Left column -->
          <div class="template-content-layout-column-left">
            <!-- Widgets list -->
            <ul class="template-widget-list">
              <!-- Widget -->
              <li>
                <div class="template-widget">
                  <!-- Widget header -->
                  <h6>Search Blog</h6>

                  <!-- Search widget -->
                  <div class="template-widget-search">
                    <div class="template-component-search-form">
                      <form @submit.prevent="searchBlog">
                        <div>
                          <input type="search" v-model="keyword" @input="searchBlog" required />
                          <span class="template-icon-meta-search"></span>
                          <input type="submit" style="color: transparent" />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </li>

              <!-- Widget -->
              <li>
                <div class="template-widget">
                  <h6>Latest Posts</h6>

                  <!-- Latest post widget -->
                  <div class="template-widget-latest-post">
                    <ul v-if="latest_blogs.length > 0">
                      <li v-for="(item, index) in latest_blogs" :key="index">
                        <a href="#" @click.prevent="getBlogData(item.slug)">
                          <img
                            v-if="item.thumbnail != null"
                            style="width: 100px;height: 100px;"
                            :src="this.$imageUrl+item.thumbnail"
                          />
                          <span>{{ item.title}}</span>
                          <span class="template-icon-meta-time">
                            <span>{{ formatDate(item.created_at) }}</span>
                          </span>
                        </a>
                      </li>
                    </ul>
                    <p v-if="latest_blogs.length == 0">No Data Found!</p>
                  </div>
                </div>
              </li>

              <!-- Widget -->
              <li>
                <div class="template-widget">
                  <h6>Most commented</h6>

                  <!-- Most commented widget -->
                  <div class="template-widget-most-comment">
                    <ul v-if="most_commented_posts.length > 0">
                      <li v-for="(item, index) in most_commented_posts" :key="index">
                        <a href="#" @click.prevent="getBlogData(item.slug)">
                          <img
                            style="width: 100px; height: 100px;"
                            v-if="item.thumbnail != null"
                            :src="this.$imageUrl+item.thumbnail"
                          />
                          <span>{{ item.title }}</span>
                          <span class="template-icon-meta-comment">
                            <span>{{ item.total_comment }} Comments</span>
                          </span>
                        </a>
                      </li>
                    </ul>
                    <p v-if="most_commented_posts.length == 0">No Data Found!</p>
                  </div>
                </div>
              </li>

              <!-- Widget -->
              <li>
                <div class="template-widget">
                  <h6>Categories</h6>

                  <!-- Categories widget -->
                  <div class="template-widget-category">
                    <ul v-if="categories.length > 0">
                      <li v-for="(item, index) in categories" :key="index">
                        <a href="#">
                          <span>{{ item.title }}</span>
                          <span>{{ item.total }}</span>
                        </a>
                      </li>
                    </ul>
                    <p v-if="categories.length == 0">No Data Found!</p>
                  </div>
                </div>
              </li>

              <!-- Widget -->
              <li>
                <div class="template-widget">
                  <h6>Popular Tags</h6>

                  <!-- Popular tags widget -->
                  <div class="template-widget-tag">
                    <ul v-if="popular_tags.tags">
                      <li v-for="(item, index) in popular_tags.tags.split(',')" :key="index">
                        <a href="javascript:void(0)">{{ item }}</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>

              <!-- Widget -->
              <li>
                <div class="template-widget">
                  <h6>Archives</h6>

                  <!-- Archive widget -->
                  <div v-if="archive_posts" class="template-widget-archive">
                    <ul v-if="archive_posts.length > 0">
                      <li v-for="(item, index) in archive_posts" :key="index">
                        <a>
                          <span>{{ item.month_name }}</span>
                          <span>{{ item.total_post }}</span>
                        </a>
                      </li>
                    </ul>
                    <p v-if="archive_posts.length == 0">No Data Found!</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <!-- Right column -->
          <div class="template-content-layout-column-right">
            <!-- Post -->
            <div v-if="blog" class="template-blog">
              <!-- Header -->
              <div class="template-blog-header">
                <!-- Date -->
                <div>
                  <div class="template-blog-header-date">
                    <span>{{ getFormatMonthName(blog.created_at) }}</span>
                    <span>{{ getFormatDay(blog.created_at) }}</span>
                    <span>{{ getFormatYear(blog.created_at) }}</span>
                  </div>
                </div>

                <!-- Header + meta -->
                <div>
                  <!-- Header -->
                  <h3 class="template-blog-header-header">
                    <router-link :to="'/blog/'+blog.slug">{{ blog.title }}</router-link>
                  </h3>

                  <!-- Meta -->
                  <div class="template-blog-header-meta">
                    <span class="template-icon-meta-user">
                      <router-link :to="'/blog/'+blog.slug">{{ blog.author_name }}</router-link>
                    </span>
                    <span class="template-icon-meta-category">
                      <router-link :to="'/blog/'+blog.slug">{{ blog.category.title }}</router-link>
                    </span>
                    <span class="template-icon-meta-comment">
                      <router-link :to="'/blog/'+blog.slug">{{ comments.data.length }}</router-link>
                    </span>
                  </div>
                </div>
              </div>

              <!-- Image -->
              <div class="template-blog-image">
                <div class="template-component-image template-component-image-preloader">
                  <router-link :to="'/blog/'+blog.slug">
                    <img v-if="blog.thumbnail != null" :src="this.$imageUrl+blog.thumbnail" />
                    <span class="template-component-image-hover"></span>
                  </router-link>
                </div>
              </div>

              <!-- Content -->
              <div class="template-blog-content">
                <div v-html="blog.description"></div>
              </div>

              <!-- Author info -->
              <div class="template-blog-author-info">
                <!-- Author avatar -->
                <img src="../../../public/assets/media/image/avatar.jpg" alt />

                <!-- Author name -->
                <span class="template-icon-meta-author">
                  <span>{{ blog.author_name }}</span>
                </span>

                <!-- About author -->
                <p>{{ blog.author_description }}</p>
              </div>

              <!-- Meta -->
              <div class="template-blog-meta">
                <div>
                  <!-- Social icons list -->
                  <div>
                    <span>Share:</span>
                    <ul
                      class="template-component-social-icon-list template-component-social-icon-list-3"
                    >
                      <li>
                        <a
                          href="#"
                          @click.prevent="shareOnTwitter"
                          class="template-icon-social-twitter"
                        ></a>
                      </li>
                      <li>
                        <a
                          href="#"
                          @click.prevent="shareOnFacebook"
                          class="template-icon-social-facebook"
                        ></a>
                      </li>
                      <li>
                        <a
                          href="#"
                          @click.prevent="shareOnPinterest"
                          class="template-icon-social-pinterest"
                        ></a>
                      </li>
                    </ul>
                  </div>

                  <!-- Tags -->
                  <div>
                    <span class="template-icon-meta-tag"></span>
                    <ul v-if="blog.tags">
                      <li v-for="(item, index) in blog.tags.split(',')" :key="index">
                        <a
                          href="javascript:void(0)"
                        >{{ item }}{{ (index === item.length - 1 ? " " : ", ") }}</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <!-- Comment form -->
              <div class="template-blog-comment-form">
                <!-- Header -->
                <div class="template-component-header-subheader template-align-left">
                  <h4>Leave A Comment</h4>
                  <div></div>
                </div>

                <!-- Form -->
                <form
                  name="comment-form"
                  id="comment-form"
                  method="POST"
                  action="#"
                  class="template-component-comment-form"
                  @submit.prevent="sendComment"
                >
                  <ul class="template-layout-50x50 template-layout-margin-reset template-clear-fix">
                    <li class="template-layout-column-left template-margin-bottom-reset">
                      <div class="template-component-form-field">
                        <label for="comment-form-name">Your Name *</label>
                        <input
                          type="text"
                          v-model="name"
                          name="comment-form-name"
                          id="comment-form-name"
                          required
                        />
                      </div>
                      <div class="template-component-form-field">
                        <label for="comment-form-email">Your E-mail *</label>
                        <input
                          type="email"
                          v-model="email"
                          name="comment-form-email"
                          id="comment-form-email"
                          required
                        />
                      </div>
                      <div class="template-component-form-field">
                        <label for="comment-form-phone">Phone Number</label>
                        <input
                          type="text"
                          v-model="phone"
                          name="comment-form-phone"
                          id="comment-form-phone"
                          required
                        />
                      </div>
                    </li>
                    <li class="template-layout-column-right template-margin-bottom-reset">
                      <div class="template-component-form-field">
                        <label for="comment-form-comment">Comment *</label>
                        <textarea
                          v-model="comment"
                          rows="1"
                          cols="1"
                          name="comment-form-comment"
                          id="comment-form-comment"
                          required
                        ></textarea>
                      </div>
                    </li>
                  </ul>
                  <div class="template-align-right template-clear-fix template-margin-top-1">
                    <input
                      type="submit"
                      value="Post Comment"
                      class="template-component-button"
                      name="comment-form-submit"
                      id="comment-form-submit"
                    />
                  </div>
                </form>
              </div>

              <!-- Comments list -->
              <div class="template-blog-comment-list">
                <!-- Header -->
                <div class="template-component-header-subheader template-align-left">
                  <h4>{{ comments.data.length }} Comments</h4>
                  <div></div>
                </div>

                <!-- 1st level comments list -->
                <ul>
                  <!-- List element -->
                  <!-- Comments list -->
                  <div class="template-blog-comment-list">
                    <!-- Header -->
                    <div class="template-component-header-subheader template-align-left">
                      <h4>{{ comments.data.length }} Comments</h4>
                      <div></div>
                    </div>

                    <!-- 1st level comments list -->
                    <ul v-if="comments.data.length > 0">
                      <!-- List element -->
                      <li v-for="(item, index) in comments.data" :key="index">
                        <!-- Comment -->
                        <div v-if="item.reply_comment_id == null">
                          <img src="../../../public/assets/media/image/avatar.jpg" alt />
                          <span class="template-icon-meta-author">
                            <span>{{ item.name }}</span>
                          </span>
                          <span class="template-icon-meta-time">
                            <span>{{ formatDate(item.created_at) }}</span>
                          </span>
                          <a href="javascript:void(0)" @click.prevent="commentReply(item)">Reply</a>
                          <!-- modal -->
                          <div v-if="showModal" class="modal-section" @click.self="closeModal">
                            <div class="modal-body">
                              <form @submit.prevent="sendReply">
                                <h4 style="margin-bottom: 0px;">Reply To:</h4>
                                <span class="parent-comment">{{ parentComment }}</span>
                                <input type="hidden" v-model="parentCommentID" />
                                <input
                                  type="text"
                                  placeholder="Enter Name"
                                  required
                                  v-model="replyname"
                                  class="reply_comment_input"
                                />
                                <input
                                  type="email"
                                  placeholder="Enter Email"
                                  required
                                  v-model="replyemail"
                                  class="reply_comment_input"
                                />
                                <input
                                  type="text"
                                  placeholder="Enter Phone"
                                  required
                                  v-model="replyphone"
                                  class="reply_comment_input"
                                />
                                <textarea
                                  placeholder="Write Comment"
                                  required
                                  v-model="replycomment"
                                  class="reply_comment_text"
                                ></textarea>
                                <br />
                                <button type="submit" class="reply_comment_btn">Reply</button>
                              </form>
                            </div>
                          </div>
                          <!-- modal -->
                          <p>
                            <span>{{ item.comment.substring(0, 150) }}</span>
                            <span
                              class="template-component-more-content"
                            >{{ item.comment.substring(150) }}</span>
                            <a
                              href="#"
                              v-show="item.comment.length > 150"
                              class="template-component-more-link"
                            >
                              <span>More...</span>
                              <span>Less...</span>
                            </a>
                          </p>
                        </div>

                        <comment-reply
                          v-if="item.comments.length > 0"
                          :replyList="item.comments"
                          :postId="blog.id"
                        />
                        
                      </li>
                    </ul>
                  </div>
                </ul>
              </div>

              <!-- Pagination -->
              <div class="template-pagination template-clear-fix">
                <ul>
                  <li
                    v-for="(item, index) in comments.links"
                    :key="index"
                    :class="item.active ? 'template-pagination-button-selected' : index == 0 ? 'template-pagination-button-prev' : index == (comments.links.length - 1) ? 'template-pagination-button-next' : ''"
                  >
                    <a
                      @click.prevent="paginateData(item.url, blog.slug)"
                      href="javascript:void(0)"
                      :class="item.url != null ? '' : 'disable_a_tag'"
                      class="template-icon-meta-arrow-large-rl"
                      v-if="index == 0"
                    >&nbsp;</a>
                    <a
                      @click.prevent="paginateData(item.url, blog.slug)"
                      href="javascript:void(0)"
                      :class="item.url != null ? '' : 'disable_a_tag'"
                      v-else-if="index != (comments.links.length - 1) && item.url != null"
                    >{{ item.label }}</a>
                    <a
                      @click.prevent="paginateData(item.url, blog.slug)"
                      href="javascript:void(0)"
                      :class="item.url != null ? '' : 'disable_a_tag'"
                      class="template-icon-meta-arrow-large-rl"
                      v-else-if="index == (comments.links.length - 1)"
                    >&nbsp;</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import mixin from "../../mixin";
import CommentReply from "../../components/includes/CommentReply.vue";

export default {
  name: "SingleBlog",
  components: { CommentReply, Loading },
  data() {
    return {
      blog: {},
      popular_tags: {},
      latest_blogs: [],
      categories: [],
      most_commented_posts: [],
      archive_posts: [],
      comments: [],
      name: "",
      email: "",
      phone: "",
      comment: "",
      replyname: "",
      replyemail: "",
      replyphone: "",
      replycomment: "",
      keyword: "",
      showModal: false,
      parentCommentID: "",
      parentComment: "",
      isLoading: false,
      fullPage: true,
      loader: "bars"
    };
  },
  mixins: [mixin],
  async created() {
    var slug = this.$route.params.slug;
    await this.getBlogData(slug);
    window.$(".template-component-image").templateImage();
    window
      .$(".template-layout-50x50")
      .responsiveElement({ className: "template-responsive-column-a" });
    window.$(".template-component-more-link").templateMoreLess();
  },
  methods: {
    async getBlogData(slug) {
      this.isLoading = true;
      let config = {
        method: "get",
        url: this.$apiBaseUrl + this.$api.singleBlog + "?slug=" + slug
      };
      await this.$axios(config)
        .then(response => {
          this.blog = response.data.data.blog;
          this.popular_tags = response.data.data.popular_tags;
          this.latest_blogs = response.data.data.latest_blogs;
          this.categories = response.data.data.categories;
          this.most_commented_posts = response.data.data.most_commented_posts;
          this.archive_posts = response.data.data.archive_posts;
          this.comments = response.data.data.comments;
          console.log("Blog Data", response);
          this.isLoading = false;
        })
        .catch(error => {
          console.log(error);
          this.isLoading = false;
        });
    },
    async paginateData(url, slug) {
      this.isLoading = true;
      let config = {
        method: "get",
        url: url + "&slug=" + slug
      };
      await this.$axios(config)
        .then(response => {
          this.comments = response.data.data.comments;
          console.log("Paginate comments Data", response);
          this.isLoading = false;
        })
        .catch(error => {
          console.log(error);
          this.isLoading = false;
        });
    },
    sendComment() {
      this.isLoading = true;
      var commentData = {
        name: this.name,
        email: this.email,
        phone: this.phone,
        comment: this.comment,
        post_id: this.blog.id
      };
      let config = {
        method: "post",
        url: this.$apiBaseUrl + this.$api.comment,
        data: commentData
      };
      this.$axios(config)
        .then(response => {
          if (response.data.status == false) {
            this.$swal({
              title: response.data.message,
              icon: "error"
            });
          } else {
            var slug = this.$route.params.slug;
            this.getBlogData(slug);
            this.resetData();
            this.$swal({
              title: response.data.message,
              icon: "success"
            });
          }
          this.isLoading = false;
        })
        .catch(error => {
          console.log(error);
          this.isLoading = false;
        });
    },
    resetData() {
      this.name = "";
      this.email = "";
      this.phone = "";
      this.comment = "";
      this.replyname = "";
      this.replyemail = "";
      this.replyphone = "";
      this.replycomment = "";
    },
    shareOnFacebook() {
      const navUrl =
        "https://www.facebook.com/sharer/sharer.php?u=" + window.location.href;
      window.open(navUrl, "_blank");
    },
    shareOnTwitter() {
      const navUrl =
        "https://twitter.com/intent/tweet?text=" + window.location.href;
      window.open(navUrl, "_blank");
    },
    shareOnPinterest() {
      const navUrl =
        "http://pinterest.com/pin/create/button/" +
        "?url=" +
        window.location.href +
        "&media=" +
        this.$imageUrl +
        this.blog.thumbnail +
        "&description=" +
        this.blog.short_description;
      window.open(navUrl, "_blank");
    },
    async searchBlog() {
      if (this.keyword == "") {
        var slug = this.$route.params.slug;
        await this.getBlogData(slug);
      } else {
        let config = {
          method: "get",
          url: this.$apiBaseUrl + this.$api.latestBlogSearch,
          params: {
            keyword: this.keyword
          }
        };
        await this.$axios(config)
          .then(response => {
            this.latest_blogs = response.data.data.latest_blogs;
            this.categories = response.data.data.categories;
            this.most_commented_posts = response.data.data.most_commented_posts;
            this.archive_posts = response.data.data.archive_posts;
            console.log("Search Blog Data", response);
          })
          .catch(error => {
            console.log(error);
          });
      }
      window.$(".template-component-image").templateImage();
      window
        .$(".template-layout-50x50")
        .responsiveElement({ className: "template-responsive-column-a" });
      window.$(".template-component-more-link").templateMoreLess();
    },
    commentReply(item) {
      console.log("item", item.comment);
      this.parentCommentID = item.id;
      this.parentComment = item.comment;
      this.showModal = true;
    },
    closeModal() {
      this.resetData();
      this.showModal = false;
    },
    sendReply() {
      this.isLoading = true;
      var commentData = {
        name: this.replyname,
        email: this.replyemail,
        phone: this.replyphone,
        comment: this.replycomment,
        post_id: this.blog.id,
        reply_comment_id: this.parentCommentID
      };
      let config = {
        method: "post",
        url: this.$apiBaseUrl + this.$api.comment,
        data: commentData
      };
      this.$axios(config)
        .then(response => {
          if (response.data.status == false) {
            this.closeModal();
            this.$swal({
              title: response.data.message,
              icon: "error"
            });
          } else {
            this.closeModal();
            this.resetData();

            var slug = this.$route.params.slug;
            this.getBlogData(slug);

            this.$swal({
              title: response.data.message,
              icon: "success"
            });
          }
          this.isLoading = false;
        })
        .catch(error => {
          this.closeModal();
          console.log(error);
          this.isLoading = false;
        });
    }
  }
};
</script>

<style scoped>
.modal-section {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.2);
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.modal-body {
  max-width: 500px;
  width: 100%;
  background: white;
  border-radius: 10px;
  padding: 1rem;
}

.reply_comment_input {
  margin: 5px;
  border-radius: 10px;
}

.reply_comment_text {
  margin: 5px;
  border-radius: 10px;
  width: 100%;
  border-color: #e2e6e7;
  padding: 15px;
}

.reply_comment_btn {
  margin: 10px;
  border-radius: 5px;
  border: 1px solid;
  width: 100%;
  padding: 5px;
  background: #ee9134;
  color: white;
  cursor: pointer;
}
</style>