<template>
  <div>
    <div class="template-footer">
      <div class="template-main">
        <!-- Footer top -->
        <div class="template-footer-top">
          <!-- Layout 25x25x25x25 -->
          <div class="template-layout-25x25x25x25 template-clear-fix">
            <!-- Left column -->
            <div class="template-layout-column-left">
              <h6>About</h6>
              <p>{{ footer.about }}</p>
              <img :src="this.$imageUrl + footer.footer_logo" class="template-logo" />
            </div>

            <!-- Center left column -->
            <div class="template-layout-column-center-left">
              <h6>Quick Links</h6>
              <ul class="template-list-reset">
                <li v-for="(item, index) in quick_links" :key="index">
                  <router-link :to="item.link">{{ item.title }}</router-link>
                </li>
              </ul>
            </div>

            <!-- Center right column -->
            <div class="template-layout-column-center-right">
              <h6>Working Hour</h6>
              <span style="white-space: pre-line">{{ contact.working_hours }}</span>
              <br />
              <br />
              <h6>Phone Number</h6>
              <p>
                {{ contact.phone_one }}
                <br />
                {{ contact.phone_two }}
                <br />
              </p>
            </div>

            <!-- Right column -->
            <div class="template-layout-column-right">
              <h6>Newsletter</h6>
              <form @submit.prevent="subscribe" class="template-component-newsletter-form">
                <div class="template-component-form-field">
                  <label for="newsletter-form-email">Your e-mail address *</label>
                  <input
                    type="email"
                    v-model="email"
                    name="newsletter-form-email"
                    id="newsletter-form-email"
                    required
                  />
                </div>
                <div class="template-margin-top-2">
                  <input
                    type="submit"
                    value="Subscribe"
                    class="template-component-button"
                    name="newsletter-form-submit"
                    id="newsletter-form-submit"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>

        <!-- Footer bottom -->
        <div class="template-footer-bottom">
          <ul class="template-component-social-icon-list template-component-social-icon-list-2">
            <li>
              <a :href="footer.fb_link" class="template-icon-social-facebook" target="_blank"></a>
            </li>
            <li>
              <a :href="footer.insta_link" class="template-icon-social-instagram" target="_blank"></a>
            </li>
          </ul>
          <!-- Copyright -->
          <div class="template-footer-bottom-copyright">
            Built by
            <a href="https://neutrix.co" target="_blank">Neutrix</a> | Powered by
            <a href="https://neutrix.systems" target="_blank">Neutrix Systems</a>
            |
            {{ footer.copyright_text }}
            <a
              :href="footer.copyright_site_link"
              target="_blank"
            >{{ footer.copyright_site_name }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      email: "",
      footer: {},
      contact: {},
      quick_links: {}
    };
  },
  async created() {
    await this.getFooter();
  },
  methods: {
    async getFooter() {
      let config = {
        method: "get",
        url: this.$apiBaseUrl + this.$api.footer
      };
      await this.$axios(config)
        .then(response => {
          this.footer = response.data.data.footer;
          this.contact = response.data.data.contact;
          this.quick_links = response.data.data.quick_links;
          console.log("footer", response);
        })
        .catch(error => {
          console.log(error);
        });
    },
    subscribe() {
      let config = {
        method: "post",
        url: this.$apiBaseUrl + this.$api.subscribe,
        data: {
          email: this.email
        }
      };
      this.$axios(config)
        .then(response => {
          if (response.data.status == false) {
            this.$swal({
              title: response.data.message,
              icon: "error"
            });
          } else {
            this.resetData();
            this.$swal({
              title: response.data.message,
              icon: "success"
            });
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    resetData() {
      this.email = "";
    }
  }
};
</script>

<style>
</style>