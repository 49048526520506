<template>
  <!-- 2nd level comments list -->
  <ul>
    <!-- List element -->
    <li v-for="(item, index) in replyList" :key="index">
      <!-- Comment -->
      <div>
        <img src="../../../public/assets/media/image/avatar.jpg" alt />
        <span class="template-icon-meta-author">
          <span>{{ item.name }}</span>
        </span>
        <span class="template-icon-meta-time">
          <span>{{ formatDate(item.created_at) }}</span>
        </span>
        <a href="javascript:void(0)" @click.prevent="commentReply(item)">Reply</a>
        <!-- modal -->
        <div v-if="showModal" class="modal-section" @click.self="closeModal">
          <div class="modal-body">
            <form @submit.prevent="sendReply">
              <h4 style="margin-bottom: 0px;">Reply To:</h4>
              <span class="parent-comment">{{ parentComment }}</span>
              <input type="hidden" v-model="parentCommentID" />
              <input
                type="text"
                placeholder="Enter Name"
                required
                v-model="replyname"
                class="reply_comment_input"
              />
              <input
                type="email"
                placeholder="Enter Email"
                required
                v-model="replyemail"
                class="reply_comment_input"
              />
              <input
                type="text"
                placeholder="Enter Phone"
                required
                v-model="replyphone"
                class="reply_comment_input"
              />
              <textarea
                placeholder="Write Comment"
                required
                v-model="replycomment"
                class="reply_comment_text"
              ></textarea>
              <br />
              <button type="submit" class="reply_comment_btn">Reply</button>
            </form>
          </div>
        </div>
        <!-- modal -->
        <p>
          <span>{{ item.comment.substring(0, 150) }}</span>
          <span class="template-component-more-content">{{ item.comment.substring(150) }}</span>
          <a href="#" v-show="item.comment.length > 150" class="template-component-more-link">
            <span>More...</span>
            <span>Less...</span>
          </a>
        </p>
      </div>

      <CommentReply v-if="item.comments.length > 0" :replyList="item.comments" :postId="postId" />

    </li>
  </ul>
</template>

<script>
import mixin from "../../mixin";
export default {
  name: "CommentReply",
  data() {
    return {
      replyname: "",
      replyemail: "",
      replyphone: "",
      replycomment: "",
      keyword: "",
      showModal: false,
      parentCommentID: "",
      parentComment: ""
    };
  },
  props: ["replyList", "postId"],
  mixins: [mixin],
  created() {
    window.$(".template-component-image").templateImage();
    window
      .$(".template-layout-50x50")
      .responsiveElement({ className: "template-responsive-column-a" });
    window.$(".template-component-more-link").templateMoreLess();
  },
  methods: {
    resetData() {
      this.replyname = "";
      this.replyemail = "";
      this.replyphone = "";
      this.replycomment = "";
    },
    commentReply(item) {
      console.log("item", item.comment);
      this.parentCommentID = item.id;
      this.parentComment = item.comment;
      this.showModal = true;
    },
    closeModal() {
      this.resetData();
      this.showModal = false;
    },
    async getBlogData(slug) {
      let config = {
        method: "get",
        url: this.$apiBaseUrl + this.$api.singleBlog + "?slug=" + slug
      };
      await this.$axios(config)
        .then(response => {
          this.blog = response.data.data.blog;
          this.popular_tags = response.data.data.popular_tags;
          this.latest_blogs = response.data.data.latest_blogs;
          this.categories = response.data.data.categories;
          this.most_commented_posts = response.data.data.most_commented_posts;
          this.archive_posts = response.data.data.archive_posts;
          this.comments = response.data.data.comments;
          console.log("Blog Data", response);
        })
        .catch(error => {
          console.log(error);
        });
    },
    sendReply() {
      var commentData = {
        name: this.replyname,
        email: this.replyemail,
        phone: this.replyphone,
        comment: this.replycomment,
        post_id: this.postId,
        reply_comment_id: this.parentCommentID
      };
      console.log("commentData", commentData);
      let config = {
        method: "post",
        url: this.$apiBaseUrl + this.$api.comment,
        data: commentData
      };
      this.$axios(config)
        .then(response => {
          if (response.data.status == false) {
            this.closeModal();
            this.$swal({
              title: response.data.message,
              icon: "error"
            });
          } else {
            this.closeModal();
            this.resetData();

            var slug = this.$route.params.slug;
            this.getBlogData(slug);

            this.$swal({
              title: response.data.message,
              icon: "success"
            });
          }
        })
        .catch(error => {
          this.closeModal();
          console.log(error);
        });
    }
  }
};
</script>

<style scoped>
.modal-section {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.2);
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.modal-body {
  max-width: 500px;
  width: 100%;
  background: white;
  border-radius: 10px;
  padding: 1rem;
}

.reply_comment_input {
  margin: 5px;
  border-radius: 10px;
}

.reply_comment_text {
  margin: 5px;
  border-radius: 10px;
  width: 100%;
  border-color: #e2e6e7;
  padding: 15px;
}

.reply_comment_btn {
  margin: 10px;
  border-radius: 5px;
  border: 1px solid;
  width: 100%;
  padding: 5px;
  background: #ee9134;
  color: white;
  cursor: pointer;
}
</style>