<template>
  <div>
    <div id="rev_slider_wrapper" class="rev_slider_wrapper fullwidthbanner-container">
      <div
        id="rev_slider"
        class="rev_slider fullwidthabanner"
        style="display:none;"
        data-version="5.0.7"
      >
        <ul>
          <li
            v-for="(slider, index) in sliders"
            :key="index"
            :data-index="'slide-'+index"
            data-transition="fade"
            data-slotamount="1"
            data-easein="default"
            data-easeout="default"
            data-masterspeed="500"
            data-rotate="0"
            data-delay="6000"
          >
            <!-- Main image -->
            <img
              :src="this.$imageUrl+slider.image"
              alt="slide-1"
              data-bgfit="cover"
              data-bgposition="center bottom"
            />
            <!-- Layers -->
            <!-- Layer 01 -->
            <div
              class="tp-caption tp-resizeme"
              data-x="['center','center','center','center','center']"
              data-hoffset="['0','0','0','0','0']"
              data-y="['middle','middle','middle','middle','middle']"
              data-voffset="['-120','-105','-91','-33','-36']"
              data-fontsize="['17','17','17','15','14']"
              data-fontweight="['700','700','700','700','900']"
              data-lineheight="['17','17','17','15','27']"
              data-whitespace="['nowrap','nowrap','nowrap','nowrap','normal']"
              data-width="['auto','auto','auto','auto','300']"
              data-height="auto"
              data-basealign="grid"
              data-transform_idle="o:1;"
              data-transform_in="o:1;x:[175%];y:0px;z:0px;s:2000;e:Power4.easeInOut;"
              data-transform_out="o:0;x:0px;y:0px;z:0px;s:1000;e:Power4.easeInOut;"
              data-mask_in="x:[-100%];y:0px;s:inherit;e:inherit;"
              data-mask_out="x:inherit;y:inherit;s:inherit;e:inherit;"
              data-start="100"
              data-splitin="none"
              data-splitout="none"
              data-responsive_offset="on"
              style="letter-spacing: 2px;"
            >{{ slider.title}}</div>

            <!-- Layer 02 -->
            <div
              class="tp-caption tp-resizeme"
              data-x="['center','center','center','center','center']"
              data-hoffset="['0','0','0','0','0']"
              data-y="['middle','middle','middle','middle','middle']"
              data-voffset="['-41','-35','-29','17','26']"
              data-fontsize="['62','55','43','29','22']"
              data-fontweight="['900','900','900','700','700']"
              data-lineheight="['62','55','43','29','32']"
              data-whitespace="['nowrap','nowrap','nowrap','nowrap','normal']"
              data-width="['auto','auto','auto','auto','300']"
              data-height="auto"
              data-basealign="grid"
              data-transform_idle="o:1;"
              data-transform_in="o:1;x:0px;y:[100%];z:0px;s:2000;e:Power4.easeInOut;"
              data-transform_out="o:1;x:0px;y:[100%];z:0px;s:1000;e:Power4.easeInOut;"
              data-mask_in="x:0px;y:0px;s:inherit;e:inherit;"
              data-mask_out="x:inherit;y:inherit;s:inherit;e:inherit;"
              data-start="1000"
              data-splitin="none"
              data-splitout="none"
              data-responsive_offset="on"
              style="letter-spacing: 4px;"
            >{{ slider.header_text }}</div>

            <!-- Layer 03 -->
										<div class="tp-caption tp-resizeme" 
											data-x="['center','center','center','center','center']" data-hoffset="['0','0','0','0','0']" 
											data-y="['middle','middle','middle','middle','middle']" data-voffset="['41','35','29','60','74']"
											data-fontsize="['62','55','43','29','22']"
											data-fontweight="['900','900','900','700','700']"
											data-lineheight="['62','55','43','29','32']"
											data-whitespace="['nowrap','nowrap','nowrap','nowrap','normal']"
											data-width="['auto','auto','auto','auto','300']"
											data-height="auto"
											data-basealign="grid"

											data-transform_idle="o:1;"

											data-transform_in="o:1;x:0px;y:[100%];z:0px;s:2000;e:Power4.easeInOut;"
											data-transform_out="o:1;x:0px;y:[100%];z:0px;s:1000;e:Power4.easeInOut;"

											data-mask_in="x:0px;y:0px;s:inherit;e:inherit;" 
											data-mask_out="x:inherit;y:inherit;s:inherit;e:inherit;" 

											data-start="1500"

											data-splitin="none" 
											data-splitout="none" 
											data-responsive_offset="on"
											style="letter-spacing: 4px;"
											>

											{{ slider.header_text_two }}
										</div>

            <!-- Layer 04 -->
            <div
              class="tp-caption tp-resizeme"
              data-x="['center','center','center','center','center']"
              data-hoffset="['0','0','0','0','0']"
              data-y="['middle','middle','middle','middle','middle']"
              data-voffset="['150','137','121','142','165']"
              data-fontsize="['15','15','15','15','15']"
              data-fontweight="['400','400','400','400','400']"
              data-lineheight="['15','15','15','15','15']"
              data-whitespace="['nowrap','nowrap','nowrap','nowrap','nowrap']"
              data-width="['auto','auto','auto','auto','auto']"
              data-height="auto"
              data-basealign="grid"
              data-transform_idle="o:1;"
              data-transform_in="o:1;x:0px;y:[-100%];z:0px;s:1500;e:Power4.easeInOut;"
              data-transform_out="o:1;x:0px;y:[100%];z:0px;s:750;e:Power4.easeInOut;"
              data-mask_in="x:0px;y:0px;s:inherit;e:inherit;"
              data-mask_out="x:inherit;y:inherit;s:inherit;e:inherit;"
              data-start="2500"
              data-splitin="none"
              data-splitout="none"
              data-responsive_offset="on"
            >
              <router-link
                :title="slider.button_text"
                class="template-component-button template-color-white"
                :to="slider.button_link"
              >{{ slider.button_text }}</router-link>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Slider",
  data() {
    return {
      sliders: []
    };
  },
  async created() {
    await this.getSlider();
    this.sliderInit();
  },
  methods: {
    async getSlider() {
      let config = {
        method: "get",
        url: this.$apiBaseUrl + this.$api.sliders
      };
      await this.$axios(config)
        .then(response => {
          console.log(response);
          this.sliders = response.data.data;
          //this.sliderInit();
        })
        .catch(error => {
          console.log(error);
        });
    },
    sliderInit() {
      var tpj = jQuery;
      var revapi4;
      if (tpj("#rev_slider").revolution == undefined) {
        revslider_showDoubleJqueryError("#rev_slider");
      } else {
        revapi4 = tpj("#rev_slider")
          .show()
          .revolution({
            jsFileLocation: "assets/script/",
            sliderType: "standard",
            sliderLayout: "fullwidth",
            dottedOverlay: "none",
            delay: 9000,
            responsiveLevels: [1920, 1189, 959, 767, 479],
            gridwidth: [1170, 940, 750, 460, 300],
            gridheight: [750, 600, 550, 550, 550],
            lazyType: "none",
            navigation: {
              keyboardNavigation: "on",
              mouseScrollNavigation: "off",
              onHoverStop: "on",
              keyboard_direction: "horizontal",
              touch: {
                touchenabled: "on",
                swipe_treshold: 75,
                swipe_min_touches: 1,
                drag_block_vertical: false,
                swipe_direction: "horizontal"
              },
              arrows: {
                style: "preview1",
                enable: true,
                hide_onmobile: true,
                hide_onleave: true,
                hide_delay: 200,
                hide_delay_mobile: 1500,
                hide_under: 0,
                hide_over: 9999,
                tmp: "",
                left: {
                  h_align: "left",
                  v_align: "center",
                  h_offset: 0,
                  v_offset: 0
                },
                right: {
                  h_align: "right",
                  v_align: "center",
                  h_offset: 0,
                  v_offset: 0
                }
              },
              bullets: {
                style: "preview1",
                enable: true,
                hide_onmobile: true,
                hide_onleave: true,
                hide_delay: 200,
                hide_delay_mobile: 1500,
                hide_under: 0,
                hide_over: 9999,
                direction: "horizontal",
                h_align: "center",
                v_align: "bottom",
                space: 10,
                h_offset: 0,
                v_offset: 20,
                tmp:
                  '<span class="tp-bullet-image"></span><span class="tp-bullet-title"></span>'
              }
            },
            shadow: 0,
            spinner: "spinner1",
            stopAfterLoops: -1,
            stopAtSlide: -1,
            disableProgressBar: "on",
            shuffle: "off",
            autoHeight: "off",
            hideSliderAtLimit: 0,
            hideCaptionAtLimit: 0,
            hideAllCaptionAtLilmit: 0,
            debugMode: false,
            fallbacks: {
              simplifyAll: "off",
              nextSlideOnWindowFocus: "off",
              disableFocusListener: false
            }
          });
      }
    }
  }
};
</script>

<style>
</style>