<template>
  <div>
    <div class="template-main">
      <div class="template-header-bottom-page-title">
        <h1>{{ header.header_text }}</h1>
      </div>

      <div class="template-header-bottom-page-breadcrumb">
        <router-link :to="{path: '/'}" @click="closeMenu">Home</router-link>
        <span class="template-icon-meta-arrow-right-12" v-if="header"></span>
        <a href="javascript:void(0)">{{ header.header_text }}</a>
      </div>
      <button id="passHeaderDataButton" style="visibility:hidden" @click.prevent="clickChild">{{ header.header_text }}</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "BreadCrumb",
  data() {
    return {
      header: {}
    };
  },
  async created() {
    await this.getHeader(this.$route.name);
    this.$nextTick(() => {
      document.getElementById("passHeaderDataButton").click();
    });
  },
  methods: {
    clickChild() {
      this.$emit("childClick", this.header);
    },
    async getHeader(pageTitle) {
      let config = {
        method: "get",
        url: this.$apiBaseUrl + this.$api.header,
        params: {
          page: pageTitle
        }
      };
      await this.$axios(config)
        .then(response => {
          this.header = response.data.data;
          console.log("header", response);
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  watch: {
    async $route(to, from) {
      console.log("from", from);
      console.log("to", to.name);
      await this.getHeader(to.name);
      this.$nextTick(() => {
        document.getElementById("passHeaderDataButton").click();
      });
    }
  }
};
</script>

<style>
</style>